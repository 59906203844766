import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ApiUrl from "../../../../api/ApiUrl";
import { Link } from 'react-router-dom';

function ProductSlider(props) {

    let settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    swipeToSlide: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: true,
                    autoplay: true
                }
            }
        ]
    };
    const alsoLick = props.alsoLick
    return (
        <div>
            <div className="row">
                <Slider {...settings}>
                    {
                        alsoLick.map((dt, i) => {
                            return (
                                <div key={i}>
                                     <Link to={`/product-details/${dt.slug}`}>
                                        <div onClick={() => props.setSlug(dt.slug)}
                                            className="single-smlr-product single-product-wrap">
                                            <div className="smlr-prdct-img single-product-img-wrap">
                                                {dt.current_stock === 0 ? (<span className="slider-product-stock">Stock Out</span>) : ""}
                                                <img src={ApiUrl.showImage(dt.thumbnail_image)} alt={dt.name}
                                                    className="img-fluid" />
                                            </div>
                                            <div className="smlr-prdct-details">
                                                <div className="smlr-prdct-name">
                                                    <p>{dt.name}</p>
                                                </div>
                                                <div className="smlr-prdct-price">
                                                    <p className="product-price mb-0">{dt.base_discounted_price !== dt.base_price ? (<div><del style={{ marginRight: "5px", color: "#f00" }}>৳ {dt.base_price}</del> <span style={{ fontWeight: "600" }}>৳ {dt.base_discounted_price}</span></div>) : (<div>৳ {dt.base_price}</div>)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    );
}

export default ProductSlider
