import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import facebookImg from "../../../dist/images/icon/facebook.png";
import twitterImg from "../../../dist/images/icon/twitter.png";
import youtubeImg from "../../../dist/images/icon/youtube.png";
import ApiUrl from "../../../api/ApiUrl";
import Axios from "axios"
import { connect } from "react-redux";
import { eshop } from "../../../redux/reducers";

const Footer = (props) => {

    useEffect(() => {
        if (props.generalSettings.facebook == null) {
            generalSettings();
        }
    }, [])

    const generalSettings = () => {
        Axios.get(ApiUrl.generalSettings).then(response => {
            if (response.status === 200) {
                props.setGeneralSettings(response.data.data[0]);
            }
        }).catch(error => {
            console.log("GeneralSettings Api Request Fail")
        })
    }
    return (
        <footer>
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <p className="mt-5 batl-txt">© OhDhaka</p>
                    </div>
                    <div className="col-md-7 col-12">
                        <nav>
                            <div className="d-flex align-items-center justify-content-center my-0 mx-auto flex-wrap" style={{ float: "left" }}>
                                <Link className="nav-link text-uppercase" to='/faqs'>Faq</Link>
                                <Link className="nav-link text-uppercase" to='/privacy-policy'>Privacy policy</Link>
                                <Link className="nav-link text-uppercase" to='/terms-condition'>Terms & Conditions</Link>
                            </div>
                        </nav>
                    </div>
                    <div className="col-md-2 col-12">
                        <div className="footer-social-main">
                            <div className="footer-social-icon mt-1">
                                <a href={props.generalSettings.facebook} target="_blank" rel="noreferrer"><img src={facebookImg} alt="search" /></a>
                                <a href={props.generalSettings.twitter} target="_blank" rel="noreferrer"><img src={twitterImg} alt="search" /></a>
                                <a className="youtube-icon mt-1" href={props.generalSettings.youtube} target="_blank" rel="noreferrer"><img src={youtubeImg} alt="search" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
const mapStateToProps = state => ({
    generalSettings: state.builder.generalSettings,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(Footer);