import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Header from '../Header/Header';
import MobileHeader from '../Header/MobileHeader';
import Footer from '../Footer/Footer';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus, faHeart, faAngleDown, } from '@fortawesome/free-solid-svg-icons'
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons'
import ProductSlider from './components/ProductSlider'
import RecentViewSlider from './components/RecentViewSlider';
import SizeChart from './components/SizeChart';
import Axios from "axios";
import ApiUrl from "../../../api/ApiUrl";
import LocalStorageHelper from "../../../LocalStorageHelper/LocalStorageHelper";
import toast, { Toaster } from "react-hot-toast";
import ImageModal from './components/ImageModal';
import ReactPlaceholder from "react-placeholder";
import Loader from "../../../Loader/Loader";
import { connect } from "react-redux";
import { eshop } from "../../../redux/reducers";

const ProductDetails = (props) => {
    let { slug } = useParams();
    const [prodQuantity, setProductCount] = useState(1);
    const [prpSize, setSize] = useState("");
    const [color, setColor] = useState("");
    const [relatedProduct, setRelatedProduct] = useState([]);
    const [product, setProduct] = useState({});
    const [choiceOptions, setChoiceOptions] = useState([]);
    const [colors, setColors] = useState([]);
    const [recentlyView, setRecentlyView] = useState([]);
    const [validation, setValidation] = useState("");
    const [validationSize, setValidationSize] = useState("");
    const [deSlug, setSlug] = useState(slug)
    const [ready, setReady] = useState(0)
    const [cartDisable, setCartDisable] = useState(false)
    const countInc = () => {
        setProductCount(prodQuantity + 1);
    }
    const countDec = () => {
        if (prodQuantity > 1) {
            setProductCount(prodQuantity - 1);
        }
    }
    const [token] = useState(props.accessToken);
    const [type, setType] = useState();
    const [sizeActive, setSizeActive] = useState(false)
    const [sizeSelected, setSizeSelected] = useState("");
    const [colorActive, setColorActive] = useState(false)
    const [colorSelected, setColorSelected] = useState("");
    const [sizeChoose, setSizeChoose] = useState("Choose")
    const [colorChoose, setColorChoose] = useState("Choose")
    const [wishlistsAdd, setWishlistsAdd] = useState(false)

    useEffect(() => {
        window.scroll(0, 0)
        if (props.accessToken) {
            setType("user")
            props.setPreviousUrl();
        } else {
            if (props.guestId.length === 0) {
                setType("guest");
                props.setGuestId(LocalStorageHelper.sessionId("Eshopping"));
            }
        }
    }, [])
    useEffect(() => {
        window.scroll(0, 0)
        setColorSelected("")
        setSizeSelected("")
        setProductCount(1)
        setWishlistsAdd(false)
        if (deSlug) {
            Axios.get(ApiUrl.ProductDetails(deSlug)).then(response => {
                if (response.status === 200) {
                    setProduct(response.data.data)
                    setChoiceOptions(response.data.data.choice_options)
                    setColors(response.data.data.colors)
                    setRelatedProduct(response.data.data.related_products)
                    setReady(1)
                    if (props.accessToken) {
                        recentlyViewedStore(props.userInfo.id, "", "user", response.data.data.id);
                        recentlyViewed(props.userInfo.id, "user")
                        wishlistsAlreadyAdd(props.userInfo.id, response.data.data.id);
                    } else {
                        recentlyViewedStore("", props.guestId, "guest", response.data.data.id);
                        recentlyViewed(props.guestId, "guest");
                    }
                }
            }).catch(error => {
                // toast.error("Request failed ! try again");
            })
        }
    }, [deSlug]);
    useEffect(() => {
        if (color && prpSize) {
            setCartDisable(true)
            let myFormData = new FormData();
            myFormData.append("id", product.id);
            myFormData.append("color", color);
            myFormData.append("variant", prpSize.replace(/ /g,''));
            Axios.post(ApiUrl.ProductVariant, myFormData).then((response) => {
                if (response.status === 200) {
                    setProduct({
                        ...product,
                        base_price: response.data.price,
                        base_discounted_price: response.data.base_discounted_price,
                        current_stock: response.data.current_stock,
                        variant: response.data.variant,
                    });
                    setCartDisable(false)
                }
            }).catch(function () {
                // toast.error("Request failed ! try again");
            })
        }
    }, [color, prpSize])
    const getColor = (value) => {
        setColorSelected(value);
        setColorChoose("");
        setColorActive(false);
        setValidation("");
        setColor(value)
    }
    const getSize = (value) => {
        setSizeSelected(value);
        setSizeChoose("")
        setSizeActive(false)
        setValidationSize("")
        setSize(value)
    }
    const colorShow = (event) => {
        setColorActive(true)
        setSizeActive(false)
    }
    const sizeShow = () => {
        setSizeActive(true)
        setColorActive(false)
    }
    const recentlyViewed = (u, t) => {
        Axios.get(ApiUrl.recentlyViewed(u, t)).then(response => {
            if (response.status === 200) {
                setRecentlyView(response.data.data)
            }
        }).catch(error => {

        })
    }
    const wishlistsAlreadyAdd = (user_id, product_id) => {
        Axios.get(ApiUrl.wishlistsAlreadyAdd(user_id, product_id), { headers: { 'Authorization': `Bearer  ${token}` } }).then(response => {
            if (response.status === 200) {
                response.data.data.product_id ? setWishlistsAdd(true) : setWishlistsAdd(false)
            }
        }).catch(error => {
            if (error.response.status == 401) {
                props.setAccessToken();
                props.setUserInfo({});
                window.open("/login", "_self");
            }
        })
    }
    const recentlyViewedStore = (u, g, t, proId) => {
        let myFormData = new FormData();
        myFormData.append("user_id", u);
        myFormData.append("guest_id", g);
        myFormData.append("type", t);
        myFormData.append("product_id", proId);
        Axios.post(ApiUrl.recentlyViewedStore, myFormData).then((response) => {
            if (response.status === 200 && response.data.status === 0) {
                for (let error of response.data.message) {
                    toast.error(error)
                }
            }
        }).catch(function () {
            // toast.error("Request failed ! try again");
        })
    }
    const addToCart = () => {
        let userIdentity;
        let type;
        if (props.userInfo.id != null) {
            userIdentity = props.userInfo.id;
            type = "user";
        } else {
            userIdentity = props.guestId;
            type = "guest";
        }
        if (product.variant_product == 1) {
            if (colorSelected === '' || sizeSelected === '') {
                if (colorSelected === '') {
                    setValidation("validation-select-box")
                }
                if (sizeSelected === '') {
                    setValidationSize("validation-select-box")
                }
            } else {
                let myFormData = new FormData();
                myFormData.append("id", product.id);
                myFormData.append("type", type);
                myFormData.append("user_id", userIdentity);
                myFormData.append("quantity", prodQuantity);
                myFormData.append("variant", prpSize);
                myFormData.append("color", color);
                let newCartProduct = {
                    id: product.id,
                    type,
                    user_id: userIdentity,
                    quantity: prodQuantity,
                    current_stock: product.current_stock,
                    name: product.name,
                    slug: product.slug,
                    base_price: product.base_price,
                    base_discounted_price: product.base_discounted_price,
                    discount_type: product.discount_type,
                    discount: product.discount ? product.discount : 0,
                    tax_type: product.tax_type,
                    tax: product.tax ? product.tax : 0,
                    variant: product.variant,
                    thumbnail_image: product.thumbnail_image,
                };
                let cart = props.cart;
                let checkSameProductMultiple = props.cart.find(c => c.id === product.id && c.variant === product.variant);
                if (checkSameProductMultiple) {
                    for (let cartProduct of cart) {
                        if (cartProduct.variant === product.variant) {
                            if(cartProduct.current_stock > cartProduct.quantity) {
                                cartProduct.quantity += 1;
                                // setProductCount(cartProduct.quantity)
                                toast.success("Product added successfully");
                            }else{
                                toast.error("Out Of Stock");
                            }
                        }
                    }
                } else {
                    cart = [...cart, newCartProduct]
                    toast.success("Product added successfully");
                }
                props.setCart([...cart]);
            }
        } else {
            let myFormData = new FormData();
            myFormData.append("id", product.id);
            myFormData.append("type", type);
            myFormData.append("user_id", props.userInfo.id);
            myFormData.append("quantity", prodQuantity);
            myFormData.append("variant", prpSize);
            myFormData.append("color", color);
            let newCartProduct = {
                id: product.id,
                type,
                user_id: userIdentity,
                quantity: prodQuantity,
                current_stock: product.current_stock,
                name: product.name,
                slug: product.slug,
                base_price: product.base_price,
                base_discounted_price: product.base_discounted_price,
                discount_type: product.discount_type,
                discount: product.discount ? product.discount : 0,
                tax_type: product.tax_type,
                tax: product.tax ? product.tax : 0,
                variant: product.variant,
                thumbnail_image: product.thumbnail_image,
            };
            let cart = props.cart;
            let checkSameProduct = props.cart.find(c => c.id === product.id);
                if(checkSameProduct){
                    if(checkSameProduct.current_stock > checkSameProduct.quantity){
                        checkSameProduct.quantity += 1;
                        props.setCart([...props.cart]);
                        toast.success("Product added successfully");
                    }else{
                        toast.error("Out Of Stock");
                    }
                }else{
                    props.setCart([...props.cart, newCartProduct]);
                    toast.success("Product added successfully");
                }
         }
    }

    const addWishList = (id) => {
        let myFormData = new FormData();
        myFormData.append("user_id", props.userInfo.id);
        myFormData.append("product_id", id);
        Axios.post(ApiUrl.AddWishListProduct, myFormData, { headers: { 'Authorization': `Bearer  ${token}` } }).then((response) => {
            if (response.status === 200 && response.data.status === 1) {
                for (let success of response.data.message) {
                    toast.success(success);
                    setWishlistsAdd(true)
                }
            } else if (response.status === 200 && response.data.status === 0) {
                for (let error of response.data.message) {
                    toast.error(error)
                }
                this.setState({ btn: "Continue" })
            }
        }).catch(error => {
            if (error.response.status === 401) {
                props.setAccessToken();
                props.setUserInfo({});
                toast.error("Please login first");
            }
        })
    };
    return (

        <div>
            <Header />
            <MobileHeader />
            <div className="container">
                <div className="product-details-main">
                    {ready === 0 ? Loader.productDetailLoader() : <div className="row">
                        <div className="col-md-7 col-12 px0">
                            {
                                product.photos && product.photos.map((img, i) => {
                                    return (
                                        <div key={i}>
                                            <ImageModal modalImage={ApiUrl.showImage(img)} />
                                            <div className="product-add-icon d-lg-none">
                                                <span>&#x2B; </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="col-md-5 col-12">
                            <ReactPlaceholder style={{ padding: 20 }} showLoadingAnimation={true} type='text'
                                ready={ready} rows={5}>
                                <div className="single-prdct-dtl-content">
                                    <div className="sp-title">
                                        <h1>{product.name}</h1>
                                    </div>
                                    <div className="sp-price">
                                        {product.base_discounted_price !== product.base_price ? (
                                            <div><p>৳ {product.base_discounted_price}</p>
                                                <del style={{
                                                    marginRight: "5px",
                                                    color: "#f00"
                                                }}>৳ {product.base_price}</del>
                                            </div>) : (<p>৳ {product.base_price}</p>)}
                                    </div>
                                    {product.variant_product ? (<div> {colors && colors.length ? (
                                        <div className="custom-select-box">
                                            <div className="custom-select-box-btn" id={validation} onClick={colorShow}>
                                                <span style={{ marginRight: 6 }}>Color : {colorChoose}</span>
                                                <span style={{
                                                    background: colorSelected,
                                                    width: 120,
                                                    height: '30px',
                                                    marginRight: 'auto'
                                                }}></span>
                                                <span className="custom-select-box-icon"> <FontAwesomeIcon
                                                    icon={faAngleDown} /></span>
                                            </div>
                                            {colorActive && (
                                                <div className="custom-select-box-content">
                                                    {colors.map((value, i) => (
                                                        <div key={i} onClick={(e) => {
                                                            getColor(value)
                                                        }}
                                                            className="custom-select-box-item"
                                                            style={{ background: value, height: "25px" }}> { }
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ) : " "}

                                        {
                                            choiceOptions.map((choice, i) => {
                                                if (choice.title === "Size") {
                                                    return (
                                                        <div className="custom-select-box" key={i}>
                                                            <div className="custom-select-box-btn" id={validationSize}
                                                                onClick={sizeShow}> Size : {sizeChoose}
                                                                {sizeSelected}
                                                                <span
                                                                    className="custom-select-box-icon"> <FontAwesomeIcon
                                                                        icon={faAngleDown} /></span>
                                                            </div>
                                                            {sizeActive && (
                                                                <div className="custom-select-box-content">
                                                                    {choice.values.map((value, i) => (
                                                                        <div key={i} onClick={(e) => {
                                                                            getSize(value)
                                                                        }}
                                                                            className="custom-select-box-item"> {value} </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                }
                                            })
                                        }</div>) : ""}


                                    <div className="input-group product-quantity">
                                        <span className="input-group-btn">
                                            <button type="button" onClick={countDec}
                                                className="quantity-left-minus btn btn-number" data-type="minus"
                                                data-field="">
                                                <FontAwesomeIcon icon={faMinus} />
                                            </button>
                                        </span>
                                        <input type="text" id="quantity" name="quantity"
                                            className="form-control input-number" value={prodQuantity}></input>
                                        <span className="input-group-btn">
                                            <button type="button" onClick={countInc}
                                                className="quantity-right-plus btn btn-success btn-number"
                                                data-type="plus" data-field="">
                                                <FontAwesomeIcon icon={faPlus} />
                                            </button>
                                        </span>
                                        <div className="prdct-save text-right">
                                            {wishlistsAdd ? <span className="prdct-save-btn"><FontAwesomeIcon
                                                icon={faHeart} /> <span>Save</span></span> :
                                                <span onClick={() => addWishList(product.id)}
                                                    style={{ cursor: 'pointer' }}
                                                    className="prdct-save-btn"><FontAwesomeIcon
                                                        icon={farHeart} /> <span>Save</span></span>}
                                        </div>
                                    </div>
                                    <div className="cart-btn">
                                        {product.current_stock >= prodQuantity ? (
                                            <button disabled={cartDisable} className="add-cart-btn"  onClick={addToCart}>add to cart</button>) : (
                                            <button className="add-cart-btn">Out Of Stock</button>)}
                                    </div>
                                </div>
                            </ReactPlaceholder>
                        </div>
                    </div>}
                    <section className="sp-accordion-wrap mt-5">
                        <ReactPlaceholder style={{ padding: 20 }} showLoadingAnimation={true} type='text' ready={ready}
                            rows={5}>
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="callops-detail">
                                        <div className="accordion accordion-flush" id="faqlist">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed description-title"
                                                        type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#faq-content-1">
                                                        description
                                                    </button>
                                                </h2>
                                                <div id="faq-content-1" className="accordion-collapse collapse"
                                                    data-bs-parent="#faqlist">
                                                    <div className="accordion-body">
                                                        {product.description}
                                                    </div>
                                                </div>
                                            </div>
                                            <SizeChart data={choiceOptions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ReactPlaceholder>
                    </section>
                    <div className="similar-product-wrap">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="smlr-prdct-title">
                                    <h2>YOU MAY ALSO LIKE</h2>
                                </div>
                            </div>
                        </div>
                        <div className="similar-product">
                            <ProductSlider alsoLick={relatedProduct} setSlug={setSlug} />
                        </div>
                    </div>
                    <div className="rcnt-view-product-wrap">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="rcnt-view-prdct-title">
                                    <h2>RECENTLY VIEWed</h2>
                                </div>
                            </div>
                        </div>
                        <div className="rcnt-view-product">
                            <RecentViewSlider recentlyView={recentlyView} setSlug={setSlug} />
                        </div>
                    </div>
                </div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{product.meta_title}</title>

                    <meta name="description" content={product?.meta_description?.replace(/(<([^>]+)>)/ig, "")} />
                    <script type="application/ld+json">{`
                              "@context": "http://schema.org",
                              "@type": "Product",
                              "id:": ${product?.id},
                              "price": ${product?.base_price},
                              
                            }
                          `}</script>
                </Helmet>
            </div>
            <Footer />
            <Toaster position="top-right" reverseOrder={false} />
            {ApiUrl.pageRefreshFun()}
        </div>
    )
}

const mapStateToProps = state => ({
    cart: state.builder.cart,
    userInfo: state.builder.userInfo,
    guestId: state.builder.guestId,
    accessToken: state.builder.accessToken,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(ProductDetails);
