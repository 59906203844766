import React, {useEffect, useState} from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import MobileHeader from '../Header/MobileHeader'
import Axios from "axios";
import ApiUrl from "../../../api/ApiUrl";
import toast from "react-hot-toast";
import ReactHtmlParser from "react-html-parser";

const PrivacyPolicy = () => {
    const [myData, setMyData] = useState({})
    useEffect(() => {
        Axios.get(ApiUrl.PrivacyPolicy).then(response => {
            if (response.status === 200) {
                setMyData(response.data.data)
            }
        }).catch(error => {
            toast.error("Request failed ! try again");
        })
    }, [])
    return (
        <div>
            <Header/>
            <MobileHeader/>
            <div className="container">
                <div className="row">
                    <div className="footer-menu-title text-center">{myData.title}</div>
                </div>
                <div className="row">
                    <div className="col-md-12" style={{textAlign: "justify"}}>
                        <div className="footer-menu-content">
                            <p>{ReactHtmlParser(myData.content)}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default PrivacyPolicy
