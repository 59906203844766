import React, {useState} from 'react'
import {Modal} from "react-bootstrap";

const ImageModal = ({modalImage}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <div className="single-product-img-modal mb-3" onClick={handleShow}>
                <img src={modalImage} alt="Modal image" title="Modal image"/>
            </div>
            <Modal className="modal-single-img-wrap" show={show} onHide={handleClose} animation={false}>

                <Modal.Body>
                    <img src={modalImage} alt="Modal image" title="Modal image"/>
                </Modal.Body>
                <Modal.Header className="image-modal-clg" closeButton style={{border: 'none'}}>
                </Modal.Header>
            </Modal>
        </div>
    )
}

export default ImageModal;
