import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, } from '@fortawesome/free-solid-svg-icons';
import Axios from "axios";
import ApiUrl from "../../../../api/ApiUrl";
import { Link } from "react-router-dom";
import {eshop} from "../../../../redux/reducers";
import {connect} from "react-redux";

const ProductWishlist = ({accessToken, wishListInfo, productWishList }) => {
    const [token] = useState(accessToken);
    const removeWishList = (id) => {
        Axios.delete(ApiUrl.RemoveWishList(id), { headers: { 'Authorization': `Bearer  ${token}` } }).then(response => {
            if (response.status === 200) {
                productWishList();
            }
        }).catch(error => {

        })
    };

    return (
        <div>
            <div className="row mt-4">
                {
                    wishListInfo.map((dt, i) => {
                        return (
                            <div key={i} className="col-lg-3 col-md-4 col-6">
                                <div className="single-wishlist-product">
                                    <div className="wishlist-prdct-img">
                                        <Link to={`/product-details/${dt.product.slug}`}>
                                            <img src={ApiUrl.showImage(dt.product.thumbnail_image)} alt="Wishlist Product Image" title="Wishlist Product Image" />
                                        </Link>
                                        <div onClick={() => removeWishList(dt.id)} className="product-wishlist-remove">
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </div>
                                    </div>
                                    <Link to={`/product-details/${dt.product.slug}`}>
                                        <div className="wishlist-prdct-details">
                                            <div className="wishlist-prdct-name">
                                                <p>{dt.product.name} {dt.base_price}</p>
                                            </div>
                                            <div className="wishlist-prdct-price">
                                                <p>&#x9f3; {dt.product.base_price}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    accessToken: state.builder.accessToken,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(ProductWishlist);
