import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';


const CartQuantity = () => {
    const[prodCouunt, setProductCount] = useState(1);
    const countInc = () => {
        setProductCount(prodCouunt + 1);
    }
    const countDec = () => {
        if (prodCouunt > 1) {
            setProductCount(prodCouunt - 1);
        }
    }
    return (
        <div className="cart-qty">
            <button onClick={countDec}>
            <FontAwesomeIcon icon={faMinus} />
            </button>
            <h4 className="mt-2">{prodCouunt}</h4>
            <button onClick={countInc}>
            <FontAwesomeIcon icon={faPlus} />
            </button>
        </div>
    );
}
export default CartQuantity;