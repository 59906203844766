import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Axios from "axios";
import ApiUrl from "../../../../api/ApiUrl";
import LocalStorageHelper from "../../../../LocalStorageHelper/LocalStorageHelper";
import toast, { Toaster } from "react-hot-toast";
import { eshop } from "../../../../redux/reducers";
import { connect } from "react-redux";
import ImageUploading from "react-images-uploading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { getMonth, getYear } from 'date-fns';
import range from "lodash/range";
import BuyerDfltImg from '../../../../dist/images/buyer/1.png';

const EditProfile = ({accessToken, setAccessToken, setUserInfo, userInfo, profile, profileDataShow, controlEditProfile, primaryAddressData }) => {

    const [name, setName] = useState(profileDataShow?.name);
    const [last_name, setLast_name] = useState(profileDataShow?.last_name);
    const [phone, setPhone] = useState(profileDataShow?.phone);
    const [email, setEmail] = useState(profileDataShow?.email);
    const [dob, setDob] = useState(profileDataShow.dob ? new Date(profileDataShow.dob) : null  );
    const [gender, setGender] = useState(profileDataShow?.gender);
    const [division, setDivision] = useState([]);
    const [divisionId, setDivisionId] = useState(profileDataShow?.division);
    const [district, setDistrict] = useState([]);
    const [districtId, setDistrictId] = useState(profileDataShow?.district);
    const [upazila, setUpazila] = useState([]);
    const [upazilaId, setUpazilaId] = useState(profileDataShow?.thana);
    const [token] = useState(accessToken);
    const [images, setImages] = React.useState();
    const maxNumber = 69;

    const years = range(1950, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
        if (imageList[0].file.name) {
            CustomerImage(imageList[0].file)
        }
    };
    const CustomerImage = (avatar) => {
        let myFormData = new FormData();
        myFormData.append("id", userInfo.id);
        myFormData.append("avatar", avatar);
        Axios.post(ApiUrl.CustomerImage, myFormData, { headers: { 'Authorization': `Bearer  ${token}` } }).then((response) => {
            if (response.status === 200 && response.data.status === 1) {

            }
        }).catch(error => {
            if (error.response.status === 401) {
                setAccessToken()
                setUserInfo({})
                window.open("/login", "_self");
            }
        })
    }

    useEffect(() => {
        Axios.get(ApiUrl.Division).then(response => {
            if (response.status === 200) {
                setDivision(response.data.data)
            }
        }).catch(error => {
            toast.error("Request Failde ! Try Again");
        })
    }, []);
    useEffect(() => {
        if (divisionId) {
            Axios.get(ApiUrl.District(divisionId)).then(response => {
                if (response.status === 200) {
                    setDistrict(response.data.data)
                }
            }).catch(error => {
                toast.error("Request Failde ! Try Again");
            })
        }
    }, [divisionId])

    useEffect(() => {
        if (districtId) {
            Axios.get(ApiUrl.Upazila(districtId)).then(response => {
                if (response.status === 200) {
                    setUpazila(response.data.data)
                }
            }).catch(error => {
                toast.error("Request Failde ! Try Again");
            })
        }
    }, [districtId])

    const getName = (event) => {
        setName(event.target.value)
    }
    const getLast_name = (event) => {
        setLast_name(event.target.value)
    }
    const getPhone = (event) => {
        setPhone(event.target.value)
    }
    const getEmail = (event) => {
        setEmail(event.target.value)
    }
    const getDob = (event) => {
        setDob(event);
    }
    const getGender = (event) => {
        setGender(event.target.value)
    }
    const getDivision = (event) => {
        setDivisionId(event.target.value)
        setUpazila([])
    }
    const getDistrict = (event) => {
        setDistrictId(event.target.value)
    }
    const getUpazila = (event) => {
        setUpazilaId(event.target.value)
    }
    const updateProfile = () => {
        if (name.length === 0 || phone.length === 0 || email.length === 0 || divisionId === "" || districtId === "" || upazila === "") {
            if (name.length === 0) {
                // toast.error("Name required")
                document.getElementById("name").style.border = "1px solid #f00";
            }
            if (phone.length === 0) {
                document.getElementById("phone").style.border = "1px solid #f00";
            }
            if (email.length === 0) {
                document.getElementById("mail").style.border = "1px solid #f00";
            }
            if (gender.length === 0) {
                document.getElementById("gender").style.border = "1px solid #f00";
            }
            if (divisionId === "") {
                document.getElementById("division").style.border = "1px solid #f00";
            }
            if (districtId === "") {
                document.getElementById("district").style.border = "1px solid #f00";
            }
            if (upazila === "") {
                document.getElementById("upazila").style.border = "1px solid #f00";
            }
        } else {
            let myFormData = new FormData();
            myFormData.append("id", userInfo.id);
            myFormData.append("name", name);
            myFormData.append("last_name", last_name);
            myFormData.append("phone", phone);
            myFormData.append("email", email);
            myFormData.append("dob", dob ?  moment(dob).format('L'): profileDataShow?.dob);
            myFormData.append("gender", gender);
            myFormData.append("division", divisionId);
            myFormData.append("district", districtId);
            myFormData.append("thana", upazilaId);

            Axios.post(ApiUrl.ProfileUpdate, myFormData, { headers: { 'Authorization': `Bearer  ${token}` } }).then((response) => {
                if (response.status === 200 && response.data.status === 1) {
                    for (let success of response.data.message) {
                        toast.success(success)
                        controlEditProfile(false);
                        profile()
                    }
                } else if (response.status === 200 && response.data.status === 0) {
                    for (let error of response.data.message) {
                        toast.error(error)
                    }
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    setAccessToken()
                    setUserInfo({})
                    window.open("/login", "_self");
                }
            })
        }
    };
    const discardProfile = () => {
        controlEditProfile(false);
    }
    return (
        <div>
            <div className="tab-content" id="ex1-content">
                <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="buyer-profile-details">
                                <div className="buyer-profile-img">
                                    <div className="">
                                        <ImageUploading
                                            value={images}
                                            onChange={onChange}
                                            maxNumber={maxNumber}
                                            dataURLKey="data_url"
                                        >
                                            {({
                                                imageList,
                                                onImageUpload,
                                                isDragging,
                                                dragProps
                                            }) => (
                                                // write your building UI
                                                <div className="upload__image-wrapper">
                                                    <div className="edit-profile-img-upld-btn"
                                                        style={isDragging ? { color: "red" } : null}
                                                        onClick={onImageUpload} {...dragProps}>
                                                        <FontAwesomeIcon icon={faCamera} />
                                                    </div>

                                                    {profileDataShow.avatar_original ? <img className="profile-dflt-img"
                                                        src={ApiUrl.showImage(profileDataShow.avatar_original)}
                                                        alt="" /> :

                                                        <img className="profile-dflt-img" src={BuyerDfltImg}
                                                            alt="" />
                                                    }

                                                    &nbsp;
                                                    {imageList.map((image, index) => (
                                                        <div key={index} className="image-item">
                                                            <img src={image.data_url} alt="" width="100" />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </ImageUploading>
                                    </div>
                                    <p className="change-profile-text"> Edit Profile</p>
                                </div>
                                <div className="edit-profile-form-wrap">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input onChange={getName} id="name" type="text" defaultValue={name} className="box-width" />
                                        </div>
                                        <div className="col-md-6">
                                            <input onChange={getLast_name} id="last_name" type="text" defaultValue={last_name} className="box-width" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input placeholder="Phone No" onChange={getPhone} id="phone" type="text"
                                                Value={phone} className="box-width" name="name"
                                                readOnly={true} />
                                        </div>
                                        <div className="col-md-6">
                                            <input placeholder="Email" id="mail" onChange={getEmail} type="text"
                                                defaultValue={email} className="box-width" name="name" />
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div onChange={getGender} className="gender-box box-width" id="gender">
                                                <input className="gender-btn" id="male" type="radio" name="gender"
                                                    value="1" checked={gender == 1 ? 1 : 0} />
                                                <label htmlFor="male">Male</label><br />
                                                <input type="radio" id="female" name="gender" value="2"
                                                    checked={gender == 2 ? 1 : 0} />
                                                <label htmlFor="female">Female</label><br />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="dob-date-picker">
                                                <DatePicker
                                                    id="dateOfBirth" className="dob-box-width"
                                                    placeholderText="Date of Birth"
                                                    selected={dob}
                                                    renderCustomHeader={({
                                                        date,
                                                        changeYear,
                                                        changeMonth,
                                                        decreaseMonth,
                                                        increaseMonth,
                                                        prevMonthButtonDisabled,
                                                        nextMonthButtonDisabled,
                                                    }) => (
                                                        <div
                                                            id="dateOfBirth"
                                                            className="box-width"
                                                            style={{
                                                                margin: 10,
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                                {"<"}
                                                            </button>
                                                            <select
                                                                value={getYear(date)}
                                                                onChange={({ target: { value } }) => changeYear(value)}
                                                            >
                                                                {years.map((option) => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <select
                                                                value={months[getMonth(date)]}
                                                                onChange={({ target: { value } }) =>
                                                                    changeMonth(months.indexOf(value))
                                                                }
                                                            >
                                                                {months.map((option) => (
                                                                    <option key={option} value={option}>
                                                                        {option}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                                {">"}
                                                            </button>
                                                        </div>
                                                    )}
                                                    onChange={getDob}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <select onChange={getDivision} id="division"
                                                className="box-width selct-box-border select-2" name="state">
                                                <option value=" " selected disabled hidden>Select Division</option>
                                                {division && division.map((v, i) => {
                                                    return (
                                                        <option selected={v.id == divisionId ? "selected" : ""}
                                                            key={i} value={v.id}>{v.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <select onChange={getDistrict} id="district"
                                                className="box-width selct-box-border select-2" name="state">
                                                <option value=" " selected disabled hidden>Select District</option>
                                                {district && district.map((v, i) => {
                                                    return (
                                                        <option selected={v.id == districtId ? "selected" : ""}
                                                            key={i} value={v.id}>{v.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <select onChange={getUpazila} id="upazila"
                                                className="box-width selct-box-border select-2" name="state">
                                                <option value=" " selected disabled hidden>Select Upazila</option>
                                                {upazila && upazila.map((v, i) => {
                                                    return (
                                                        <option selected={v.id == upazilaId ? "selected" : ""}
                                                            key={i} value={v.id}>{v.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="edit-profile-btn">
                                    <div className="update-profile-btn">
                                        <button onClick={discardProfile} className="edit-profile-discard"> DISCARD
                                        </button>
                                        <button onClick={updateProfile} className="edit-profile-update"> Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="buyer-other-info">
                                <div className="other-info-title">
                                    <h5>PRIMARY ADDRESS</h5>
                                </div>
                                <div className="other-info-detail">
                                    <div className="other-info-detail">
                                        {primaryAddressData.id ? (<div><p className="other-info-name">{primaryAddressData?.firstName} {primaryAddressData?.lastName}</p><p>{primaryAddressData?.address}, {primaryAddressData?.divisionId + ","} {primaryAddressData?.districtId} {"-" + primaryAddressData?.postal_code} </p></div>) : " "}
                                    </div>
                                </div>
                                <div className="edit-address-btn">
                                    <Link to="/my-address" className="buyer-address-edit"> EDIT addresses </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster position="top-right" reverseOrder={false} />
        </div>
    )
}

const mapStateToProps = state => ({
    userInfo: state.builder.userInfo,
    accessToken: state.builder.accessToken,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(EditProfile);

