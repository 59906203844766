import React from 'react'
import { Link } from 'react-router-dom';
import buyerImg from '../../../../dist/images/buyer/1.png';
import CProfileImg from '../../../../dist/images/buyer/chat-profile-img.png';
import CProfileImg2 from '../../../../dist/images/buyer/chat-reply-profile-img.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faWifi, faVideo, faCircle } from '@fortawesome/free-solid-svg-icons';

const Message = () => {

    return (
        <div>
           <div className="row">
               <div className="col-md-3">
                    <div className="chat-list-box message-box mt-4">
                        <div className="d-flex clb-title justify-content-between">
                           <div className="">
                                <h6 className="">INVOICE WISE CHAT</h6>
                           </div>
                           <div className="">
                                <span className=""><FontAwesomeIcon icon={ faFilter } /></span>
                           </div>
                       </div>
                       <div className="clb-list">
                           <Link to="#" className="list-item">
                                <div className="d-flex justify-content-between">
                                   <div className="inv-no">
                                        <h6 className="">ESM002329491</h6>
                                   </div>
                                   <div className="clb-time">
                                        <span className="">4:30am</span>
                                   </div>
                               </div>
                                <h6 className="clb-pname">Product Title here name</h6>
                            </Link>
                       </div>
                       <div className="clb-list">
                           <Link to="#" className="list-item">
                                <div className="d-flex justify-content-between">
                                   <div className="inv-no">
                                        <h6 className="">ESM002329491</h6>
                                   </div>
                                   <div className="clb-time">
                                        <span className="">4:30am</span>
                                   </div>
                               </div>
                                <h6 className="clb-pname">Product Title here name</h6>
                            </Link>
                       </div>
                       <div className="clb-list">
                           <Link to="#" className="list-item">
                                <div className="d-flex justify-content-between">
                                   <div className="inv-no">
                                        <h6 className="">ESM002329491</h6>
                                   </div>
                                   <div className="clb-time">
                                        <span className="">4:30am</span>
                                   </div>
                               </div>
                                <h6 className="clb-pname">Product Title here name</h6>
                            </Link>
                       </div>
                       <div className="clb-list">
                           <Link to="#" className="list-item">
                                <div className="d-flex justify-content-between">
                                   <div className="inv-no">
                                        <h6 className="">ESM002329491</h6>
                                   </div>
                                   <div className="clb-time">
                                        <span className="">4:30am</span>
                                   </div>
                               </div>
                                <h6 className="clb-pname">Product Title here name</h6>
                            </Link>
                       </div>
                   </div>
               </div>
               <div className="col-md-8">
                    <div className="message-box main-chat-body mt-4">
                       <div className="chat-header">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex ch-left">
                                    <div className="bpi-box">
                                        <img className="img-fluid rounded-circle" src={buyerImg} alt="Buyer Image" title="Buyer Image" />
                                    </div>
                                    <div className="bpi-info">
                                        <h6 className="mb-1 mt-1">Brandon Smith</h6>
                                        <p className="circle-icon mb-0"><FontAwesomeIcon icon={faCircle} /> Online</p>
                                    </div>
                                </div>
                                <div className="ch-right">
                                    <div className="d-flex">
                                        <div className="icon-box">
                                            <Link to="#"> <FontAwesomeIcon icon={faVideo} /></Link>
                                        </div>
                                        <div className="icon-box">
                                            <Link to="#"> <FontAwesomeIcon icon={faWifi} /></Link>
                                        </div>
                                    </div>
                                </div>
                           </div>
                       </div>
                       <div className="chat-body">
                           <div className="self-message d-flex">
                               <div className="sm-pimg">
                                    <img className="" src={CProfileImg} alt="Cart Profile Image" title="Cart Profile Image" />
                                    <p className="mb-0">10:00</p>
                               </div>
                               <div className="sm-message">
                                    <p>James Z</p>
                                    <p> Hello!</p>
                               </div>
                           </div>
                            <div className="feedback-message">
                                <div className="pull-right">
                                    <div className="d-flex">
                                        <div className="fm-message">
                                            <p>Geneva Z</p>
                                            <p> Hi, How are you? What about our next meeting ? </p>
                                        </div>
                                        <div className="fm-pimg">
                                            <img className="" src={CProfileImg2} alt="Cart Profile Image" title="Cart Profile Image" />
                                            <p className="mb-0">10:00</p>
                                        </div>
                                    </div>
                                </div>
                           </div>
                           <div className="self-message d-flex">
                               <div className="sm-pimg">
                                    <img className="" src={CProfileImg} alt="Cart Profile Image" title="Cart Profile Image" />
                                    <p className="mb-0">10:00</p>
                               </div>
                               <div className="sm-message">
                                    <p>James Z</p>
                                    <p> Yeah everything is fine !</p>
                               </div>
                           </div>
                            <div className="feedback-message">
                                <div className="pull-right">
                                    <div className="d-flex">
                                        <div className="fm-message">
                                            <p>Geneva Z</p>
                                            <p> Wow that’s great </p>
                                        </div>
                                        <div className="fm-pimg">
                                            <img className="" src={CProfileImg2} alt="Cart Profile Image" title="Cart Profile Image" />
                                            <p className="mb-0">10:00</p>
                                        </div>
                                    </div>
                                </div>
                           </div>
                            <div className="self-message d-flex">
                                <div className="sm-pimg">
                                    <img className="" src={CProfileImg} alt="Cart Profile Image" title="Cart Profile Image" />
                                    <p className="mb-0">10:00</p>
                                </div>
                                <div className="sm-message">
                                    <p>James Z</p>
                                    <p> Yeah everything is fine !</p>
                                </div>
                            </div>
                       </div>
                   </div>
               </div>
           </div>
        </div>
    )
}

export default Message;
