
import React, {Component, Fragment} from 'react';
import Slider from "react-slick";
import Axios from "axios";
import ApiUrl from "../../../../api/ApiUrl";
import Loader from "../../../../Loader/Loader";

class BannerSlider extends Component {
    constructor() {
        super();
        this.state={
            bannarSlider:[],
            homeSliderReady:0
        }
    }
    componentDidMount(){
        Axios.get(ApiUrl.BannarSlider).then(respons=>{
            if(respons.status === 200){
                this.setState({bannarSlider:respons.data.data})
                this.setState({homeSliderReady: 1})
        }
        }).catch(error=>{
            this.setState({homeSliderReady:0})
        })
    }
    
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        const myBannar=this.state.bannarSlider
        const viewData=myBannar.map((bannar, i)=>{
            return (
                <Fragment key={i}>
                    {
                        <div>
                            <div>
                                <img src={ApiUrl.showImage(bannar.photo)} alt="slider image" title="slider image" />
                            </div>
                        </div>
                    }
                </Fragment>
            );
        })
        return (
            <Fragment>
                <Slider {...settings}>
                    {this.state.homeSliderReady === 0 ? Loader.homeSlider() : (viewData)}
                </Slider>
            </Fragment>
        )
    }
}

export default BannerSlider;