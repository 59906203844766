import React, {Fragment, useEffect, useState} from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ApiUrl from "../../../../api/ApiUrl";
import Axios from "axios";
import {toast} from "react-hot-toast";
import ReactPlaceholder from "react-placeholder";
import {Link} from 'react-router-dom';
import Loader from "../../../../Loader/Loader";

function UpcProductSlider(props) {
    const [upCommintData, setUpCommintData] = useState([])
    const [proReady, setProReady] = useState(0)
    useEffect(() => {
        Axios.get(ApiUrl.UpCommingProduct).then(response => {
            if (response.status === 200) {
                setUpCommintData(response.data.data)
                setProReady(1)
            }
        }).catch(error => {
            toast.error("Request Failde ! Try Again");
        })
    }, [])
    var settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <div className="row">
                {proReady === 0 ? Loader.upComming() : (<Fragment><Slider {...settings}>
                    {
                        upCommintData.map((product, i) => {
                            return (
                                <ReactPlaceholder key={i} style={{padding: 20}} showLoadingAnimation={true} type='text'
                                                  ready={proReady} rows={5}>
                                    <Link to={`/product-details/${product.slug}`}>
                                        <div className="product-box text-center single-product-wrap">
                                            <div className="single-product-img-wrap">
                                                {product.current_stock === 0 ? (<span className="slider-product-stock">Stock Out</span>) : ""}
                                                <img src={ApiUrl.showImage(product.thumbnail_image)} alt={product.name}
                                                     className="img-fluid"/>
                                            </div>
                                            <div className="prod-box-content py-3 mb-3">
                                                <h5 className="product-title text-uppercase">{product.name}</h5>
                                                <h5 className="product-price mb-0">{product.base_discounted_price !== product.base_price ? (<div><del style={{marginRight: "5px", color: "#f00"}}>৳ {product.base_price}</del> <span style={{fontWeight: "600"}}>৳ {product.base_discounted_price}</span></div>) : (<div>৳ {product.base_price}</div>)}</h5>
                                            </div>
                                        </div>
                                    </Link>
                                </ReactPlaceholder>
                            )
                        })
                    }
                </Slider></Fragment>)}
            </div>
        </div>
    );
}

export default UpcProductSlider;
