import React, {Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Header/Header';
import MobileHeader from '../Header/MobileHeader';
import Footer from '../Footer/Footer';
import Axios from "axios";
import ApiUrl from "../../../api/ApiUrl";
import {toast, Toaster} from "react-hot-toast";
import NewArrSlider from './components/NewArrSlider';
import UpcProductSlider from './components/UpcProductSlider';
import Alert from 'react-bootstrap/Alert'
import Loader from "../../../Loader/Loader";
import {connect} from "react-redux";
import {eshop} from "../../../redux/reducers";

const ProductCategories = (props) => {
    const [allProduct, setAllProduct] = useState([])
    const [category, setCategory] = useState([])
    const [subCatgories, setSubCatgories] = useState([])
    const [categoryId, setCategoryId] = useState()
    const [subCategoryId, setSubCategoryId] = useState("")
    const [loading, setLoading] = useState(null)
    const [currentPage, setCurrentPage] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const[allProductReady, setAllProductReady] = useState(0)
    const[empty, setEmpty] = useState(1)
    const[btn, setBtn] = useState( "Load More")

    useEffect(() => {
        window.scroll(0, 0)
        setCategoryId(props.catId)
        setSubCategoryId(props.subCatId);
        categories();
        setTimeout(() => subCategory(props.catId), 1000);
        productFilter(props.catId, props.subCatId ? props.subCatId : "")
    }, [])

    const getCategory = (event) => {
        setCategoryId(event.target.value)
        setSubCategoryId(null)
        subCategory(event.target.value)
        productFilter(event.target.value, "")
    }
    // categories
    const categories = () => {
        Axios.get(ApiUrl.Categories).then(response => {
            if (response.status === 200) {
                setCategory(response.data.data)
            }
        }).catch(error => {
            toast.error("Request Failed ! Try Again");
        })
    }
    const getSubCategory = (event) => {
        setSubCategoryId(event.target.value);
        productFilter(categoryId, event.target.value);
    }
    const subCategory = (categoryId) => {
        Axios.get(ApiUrl.SubCategory(categoryId)).then(response => {
            if (response.status === 200) {
                setSubCatgories(response.data.data)
            }
        }).catch(error => {
            toast.error("Request Failed ! Try Again");
        })
    }
    const productFilter = (categoryId, subCategoryId) => {
        let myFormData = new FormData();
        myFormData.append("category_id", categoryId);
        myFormData.append("subcategory_id", subCategoryId);
        Axios.post(ApiUrl.ProductFilter, myFormData).then((response) => {
            if (response.status === 200 && response.data.status === 1) {
                if (response.data.data.data.length === 0) {
                    setAllProductReady(1)
                    setEmpty(0)
                    setDisabled(true)
                    setAllProduct([])
                } else {
                    let newArr = [];
                    newArr = response.data.data.data
                    setAllProduct(newArr)
                    setCurrentPage(response.data.data.current_page);
                    setAllProductReady(1)
                    setEmpty(1)
                    setDisabled(false)
                    if(response.data.data.current_page >= response.data.data.last_page ){
                        setDisabled(true)
                    }
                }
            }
        }).catch(function () {
            toast.error("Request Failed ! Try Again");
        })
    }
    const loadMore = () => {
        setBtn("loading...")
        Axios.post(ApiUrl.loadMore(currentPage + 1), {
            category_id: categoryId,
            subcategory_id: subCategoryId
        }).then(response => {
            if (response.status === 200) {
                let newArr = [];
                for (let product in response.data.data.data) {
                    newArr.push(response.data.data.data[product]);
                }
                setAllProduct([...allProduct, ...newArr]);
                setCurrentPage(response.data.data.current_page);
                setLoading(1)
                if(response.data.data.current_page >= response.data.data.last_page ){
                    setDisabled(true)
                }
                setBtn("Load More")
            }
        }).catch(error => {
            toast.error("Request Failed ! Try Again");
        })
    }
    return (
        <div>
            <Header/>
            <MobileHeader/>
            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-12 text-end mb-4">
                                <h1 className="title-h4 text-center text-uppercase mb-3" style={{fontSize: "25px"}}>aLL PRODUCTS</h1>
                                <div className="row justify-content-center">
                                    <div className="col-md-3 pb-2">
                                        <select onChange={getCategory} className="form-select" id="division_select">
                                            {category.map((cat, i) => {
                                                return (<option
                                                    selected={cat.slug === props.match.params.cat ? "selected" : ""}
                                                    value={cat.id} key={i}>{cat.name}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-3">
                                        <select onChange={getSubCategory} className="form-select" id="division_select">
                                            <option value="">All</option>
                                            {subCatgories.map((subCat, i) => {
                                                return (<option
                                                    selected={subCat.slug === props.match.params.subCat ? "selected" : ""}
                                                    value={subCat.id} key={i}>{subCat.name} </option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                allProductReady === 0 ? Loader.categoryProduct() : (<Fragment>
                                    { allProduct.map((pbd, i) => {
                                        return (
                                            <div key={i} className="col-lg-3 col-md-3 col-sm-3 col-6">
                                                <Link to={`/product-details/${pbd.slug}`}>
                                                    <div className="product-box text-center">
                                                        <div className="prod-box-img">
                                                            {pbd.current_stock === 0 ? (<span className="home-product-stock">Stock Out</span>) : ""}
                                                            <img src={ApiUrl.showImage(pbd.thumbnail_image)} alt={pbd.name}
                                                                 className="img-fluid"/>
                                                        </div>
                                                        <div className="prod-box-content py-3 mb-3">
                                                            <h5 className="product-title text-uppercase">{pbd.name}</h5>
                                                            <h5 className="product-price mb-0">{pbd.base_discounted_price !== pbd.base_price ? (<div><del style={{marginRight: "5px", color: "#f00"}}>৳ {pbd.base_price}</del> <span style={{fontWeight: "600"}}>৳ {pbd.base_discounted_price}</span></div>) : (<div>৳ {pbd.base_price}</div>)}</h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </Fragment>)
                            }
                            {empty === 0 && (
                                <Alert id="dangerMst" variant="danger" className="alrt-msg-show">
                                    <Alert.Heading className="alrt-name">Your Search Criteria Not Match.</Alert.Heading>
                                </Alert>
                            )}
                            {allProductReady === 1 &&(
                                <div className="col-lg-12 text-center mt-2">
                                    {disabled !== true &&(<button onClick={loadMore} className="text-uppercase load-more-link" >{btn}  </button>)}
                                </div>
                            )}

                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-12 text-end mb-4">
                                <h2 className="title-h4 text-center text-uppercase mb-3" style={{fontSize: "23px"}}>New Arrival</h2>
                            </div>
                            <div className="new-arr-slider">
                                <NewArrSlider/>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-lg-12 text-end mb-4">
                                <h2 className="title-h4 text-center text-uppercase mb-3" style={{fontSize: "23px"}}>Upcomming Product</h2>
                            </div>
                            <div className="upc-product-slider">
                                <UpcProductSlider/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster position="top-center" reverseOrder={false}/>
            <Footer/>
        </div>
    )
}

const mapStateToProps = state => ({
    catId: state.builder.catId,
    subCatId: state.builder.subCatId,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(ProductCategories);