import React, { Component } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link, Redirect } from 'react-router-dom';
import RecentViewSlider from '../Products/components/RecentViewSlider';
import Axios from "axios";
import ApiUrl from "../../../api/ApiUrl";
import { toast, Toaster } from "react-hot-toast";
import LocalStorageHelper from "../../../LocalStorageHelper/LocalStorageHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import MobileHeader from '../Header/MobileHeader';
import clockImg from '../../../dist/images/clock.png';
import moment from 'moment';
import { connect } from "react-redux";
import { eshop } from "../../../redux/reducers";
import { logDOM } from "@testing-library/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from 'react-bootstrap';

class ProductCart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            prodCouunt: 0,
            UserRedirect: false,
            userIdentity: "",
            type: "",
            division: [],
            divisionId: "",
            district: [],
            districtId: "",
            postCode: "",
            date: "",
            time: "",
            address: "",
            myData: [],
            token: this.props.accessToken,
            cartEmpty: false,
            shippingCost: "",
            coastShot: false,
            recentlyView: [],
            getDate: new Date(),
            checkout: false,
            showLogin: false,
            mobile: "",
            password: "",
        }
        this.countInc = this.countInc.bind(this)
        this.countDec = this.countDec.bind(this)
        this.onUserRedirect = this.onUserRedirect.bind(this)
    }

    componentDidMount() {
        if (this.props.accessToken) {
            if (this.props.estimateInfo.address != null) {
                this.setState({
                    postCode: this.props.estimateInfo.postCode,
                    address: this.props.estimateInfo.address,
                    divisionId: this.props.estimateInfo.division,
                    districtId: this.props.estimateInfo.district
                })
            } else {
               /*  setTimeout(() => {
                    this.addressSetDefault(this.props.userInfo.id);
                }, 2000) */
                this.addressSetDefault(this.props.userInfo.id);
            }
            this.divisions(this.props.estimateInfo.division);
            this.recentlyViewed(this.props.userInfo.id, "user");
            this.setState({ type: "user" });
        } else {
            if (this.props.estimateInfo.address != null) {
                this.setState({
                    postCode: this.props.estimateInfo.postCode,
                    address: this.props.estimateInfo.address,
                    divisionId: this.props.estimateInfo.division,
                    districtId: this.props.estimateInfo.district
                })
            }
            setTimeout(() => {
                this.recentlyViewed(this.props.guestId, "guest");
            }, 1000)
            this.divisions(this.props.estimateInfo.division);
            this.setState({ type: "guest" });
        }
        // Division
        Axios.get(ApiUrl.Division).then(response => {
            if (response.status === 200) {
                this.setState({ division: response.data.data })
                this.props.setDivision(JSON.stringify(response.data.data))
            }
        }).catch(error => {
            toast.error("Request Failde ! Try Again");
        });
    }

    loginModelClose = () => {
        this.setState({ ...this.state, showLogin: false })
    };
    loginModelShow = () => {
        this.setState({ ...this.state, showLogin: true })
    };

    getDivision = (event) => {
        this.setState({ divisionId: event.target.value })
        this.divisions(event.target.value)
        if (event.target.value) {
            document.getElementById("division").style.border = ""
        }
        this.setState({ checkout: false })
    }
    divisions = (divisionId) => {
        Axios.get(ApiUrl.District(divisionId)).then(response => {
            if (response.status === 200) {
                this.setState({ district: response.data.data })
                this.props.setDistrict(JSON.stringify(response.data.data))
            }
        }).catch(error => {
            toast.error("Request Failde ! Try Again divisionId");
        })
    }
    getDistrict = (event) => {
        this.setState({ districtId: event.target.value })
        if (event.target.value) {
            document.getElementById("district").style.border = ""
        }
        this.setState({ checkout: false })
    }
    shippingCost = (distictID) => {
        Axios.get(ApiUrl.ShippingCost(distictID)).then(response => {
            if (response.status === 200) {
                this.props.setEstimateInfo({
                    ...this.props.estimateInfo,
                    shippingCost: response.data.data[0].shippingCost
                })
            }
        }).catch(error => {
            // toast.error("Request Failde ! Try Again");
        })
    }
    getPostCode = (event) => {
        this.setState({ postCode: event.target.value })
        if (event.target.value) {
            document.getElementById("postCode").style.border = ""
        }
        this.setState({ checkout: false })
    }
    getAddress = (event) => {
        this.setState({ address: event.target.value })
        if (event.target.value) {
            document.getElementById("address").style.border = ""
        }
        this.setState({ checkout: false })
    }
    getDate = (event) => {
        this.setState({ date: event })
        if (event) {
            document.getElementById("date").style.border = ""
        }
        this.setState({ checkout: false })
    }
    getTime = (event) => {
        this.setState({ time: event.target.value })
        if (event.target.value !== " ") {
            document.getElementById("time").style.border = ""
        }
        this.setState({ checkout: false })
    }
    estimateSubmit = () => {
        let divisionId = this.state.divisionId;
        let districtId = this.state.districtId;
        let postCode = this.state.postCode;
        let address = this.state.address;
        let date = moment(this.state.date).format('L');
        let time = this.state.time;
        this.shippingCost(districtId);
        let shippingCost = this.props.estimateInfo.shippingCost;
        const estimateValue = {
            division: divisionId,
            district: districtId,
            postCode: postCode,
            address: address,
            date: date,
            time: time,
            shippingCost: shippingCost,
        }
        console.log("divisionId", divisionId, "postCode", postCode)
        if (address === "" || date === "Invalid date" || time === "") {
            if (this.state.divisionId === "" ) {
                document.getElementById("division").style.border = "1px solid #f00";
            }
            if (this.state.districtId === "" ) {
                document.getElementById("district").style.border = "1px solid #f00";
            }
            if (address === "" ) {
                document.getElementById("address").style.border = "1px solid #f00";
            }
            if (postCode === "" ) {
                document.getElementById("postCode").style.border = "1px solid #f00";
            }
            if (date === "Invalid date") {
                document.getElementById("date").style.border = "1px solid #f00";
            }
            if (time === "") {
                document.getElementById("time").style.border = "1px solid #f00";
            }
        } else {
            this.props.setEstimateInfo({ ...estimateValue });
            this.setState({ "coastShot": true, checkout: true })
        }
    }
    recentlyViewed = (u, t) => {
        Axios.get(ApiUrl.recentlyViewed(u, t)).then(response => {
            if (response.status === 200) {
                this.setState({ recentlyView: response.data.data })
            }
        }).catch(error => {
            // toast.error("");
        })
    }

    countInc(product_id, variant) {
        let qtyInc = this.props.cart.find(c => c.id === product_id && c.variant === variant);
        let cart = this.props.cart;
        if (qtyInc.current_stock > qtyInc.quantity) {
            qtyInc.quantity += 1;
            this.props.setCart([...cart]);
        } else {
            toast.error("Out Of Stock");
        }

        // let checkSameProductMultiple = this.props.cart.filter(c => c.id === product_id);
        // let cart = this.props.cart;
        // if(checkSameProductMultiple[0].current_stock >= checkSameProductMultiple[0].quantity){
        //     if (checkSameProductMultiple.length > 1) {
        //         for (let cartProduct of cart) {
        //             if (cartProduct.variant === variant) {
        //                 cartProduct.quantity += 1;
        //             }
        //         }
        //         this.props.setCart([...cart]);
        //     } else {
        //         for (let cartProduct of cart) {
        //             if (cartProduct.id === product_id && cartProduct.variant === variant) {
        //                 cartProduct.quantity += 1;
        //             }
        //         }
        //         this.props.setCart([...cart]);
        //     }
        // }else{
        //     toast.error("Out Of Stock");
        // }
    }

    countDec(product_id, variant) {
        let cart = this.props.cart;
        let qtyDec = cart.find(c => c.id === product_id && c.variant === variant);
        if (qtyDec.quantity > 1) {
            qtyDec.quantity -= 1;
            this.props.setCart([...cart]);
        }
    }

    cartRemove = (product_id, variant) => {
        let cart = this.props.cart;
        cart = cart.filter(item => item.id !== product_id || item.variant !== variant);
        this.props.setCart([...cart]);
    }


    cartEmptyRedirect() {
        if (this.state.cartEmpty === true) {
            return <Redirect to={{ pathname: `products` }} />
        }
    }

    addressSetDefault = (user_id) => {
        Axios.get(ApiUrl.addressSetDefault(user_id), { headers: { 'Authorization': `Bearer  ${this.state.token}` } }).then(response => {
            if (response.status == 200) {
                if (response.data.data) {
                    this.setState({ userInfo: response.data.data })
                    this.setState({
                        postCode: response.data.data.postal_code,
                        address: response.data.data.address,
                        divisionId: response.data.data.division_id,
                        districtId: response.data.data.district_id
                    })
                    this.shippingCost(response.data.data.district_id)
                    this.divisions(response.data.data.division_id);
                } else {
                    this.setState({ ...this.state, showAddress: false, addAddressShow: true });
                }
            }
        }).catch(error => {
            toast.error("Request failed ! try again");
        })
    }
    mobileOnChange = (event) => {
        let mobile = event.target.value
        this.setState({ mobile: mobile })
    }
    passwordOnChange = (event) => {
        let password = event.target.value
        this.setState({ password: password })
    }
    loginSubmit = () => {
        let mobile = this.state.mobile;
        let password = this.state.password;
        if (mobile.length === 0 || mobile.length < 11 || password.length < 8 || password.length === 0) {
            if (password.length < 8) {
                toast.error("Please check password")
            }
            if (mobile.length < 11) {
                toast.error("Please check mobile number")
            }
        } else {
            let myFormData = new FormData();
            myFormData.append("phone", mobile);
            myFormData.append("password", password);
            Axios.post(ApiUrl.Login, myFormData).then((response) => {
                if (response.status === 200 && response.data.status === 1) {
                    toast.success("Login Success")
                    this.setState({ UserRedirect: "shipping" })
                    this.props.setAccessToken(response.data.access_token)
                    this.props.setUserInfo(response.data.user)
                    this.props.setGuestInfo({})
                    this.props.setEstimateInfo({})
                } else {
                    toast.error("Please check mobile number and password")
                }
            }).catch(function (error) {
                toast.error("Request Failde ! Try Again");
            })
        }
    }

    onUserRedirect = () => {
        if (this.state.UserRedirect === "shipping") {
            return <Redirect to={{ pathname: `/cart-shipping` }} />
        } else if (this.state.UserRedirect === "info") {
            return <Redirect to={{ pathname: `/cart-info` }} />
        }
    }
    render() {
        const myDivision = this.state.division.map((division, i) => {
            return (
                <option selected={this.state.divisionId == division.id ? "selected" : ""} key={i}
                    value={division.id}>{division.name}</option>
            )
        })
        const myDistrict = this.state.district.map((district, i) => {
            return (
                <option selected={this.state.districtId == district.id ? "selected" : ""} key={i}
                    value={district.id}>{district.name}</option>
            )
        })

        if (this.props.cart.length === 0) {
            this.setState({ cartEmpty: true })
        }
        let total = 0;
        const myView = this.props.cart.map((product, v) => {
            total += (product.base_discounted_price ? product.base_discounted_price : product.base_price) * product.quantity;
            return (
                <div className="row" key={v}>
                    <div className="col-lg-5 col-md-5 col-12 d-none d-md-block">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="d-flex">
                                    <Link to={`/product-details/${product.slug}`}>
                                        <div className="product-img">
                                            <img className="img-fluid img-bg" src={ApiUrl.showImage(product.thumbnail_image)}
                                                alt="Product Image" title="Product Image" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="product-details p-3 ps-0">
                                    <h6 className="item-title">{product.name}</h6>
                                    <h6 className="item-color mb-2">{product.variant}</h6>
                                    <h6 className="item-price">৳{product.base_discounted_price ? product.base_discounted_price : product.base_price}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-12 d-block d-md-none">
                        <div className="d-flex">
                            <Link to={`/product-details/${product.slug}`}>
                                <div className="product-img">
                                    <img className="img-fluid img-bg" src={ApiUrl.showImage(product.thumbnail_image)}
                                        alt="Product Image" title="Product Image" />
                                </div>
                            </Link>
                            <div className="product-details p-3">
                                <h6 className="item-title">{product.name}</h6>
                                <h6 className="item-color mb-2">{product.variant}</h6>
                                <h6 className="item-price">৳{product.base_discounted_price ? product.base_discounted_price : product.base_price}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-4 d-sm-none d-md-none d-lg-none"></div>
                    <div className="col-lg-3 col-md-3 col-8 ">
                        <div className="row">
                            <div className="col-md-12 col-6">
                                <div className="d-flex justify-content-center mb-2 mt-lg-4">
                                    <div className="cart-qty">
                                        <button onClick={() => {
                                            this.countDec(product.id, product.variant)
                                        }}><FontAwesomeIcon icon={faMinus} /></button>
                                        <p className="m-0 py-2">{product.quantity}</p>
                                        <button onClick={() => {
                                            this.countInc(product.id, product.variant)
                                        }}><FontAwesomeIcon icon={faPlus} /></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-6 p text-center">
                                <span onClick={() => {
                                    this.cartRemove(product.id, product.variant)
                                }} className="text-dark cart-remove-link">REMOVE</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 d-none d-lg-block">
                        <div className="text-end">
                            <h6 className="mt-5">৳{(product.base_discounted_price ? product.base_discounted_price : product.base_price) * product.quantity}</h6>
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <div>
                <Modal id="addressList" show={this.state.showLogin} onHide={this.loginModelClose}
                    animation={false}>
                    <Modal.Header closeButton style={{ border: 'none' }}>
                    </Modal.Header>
                    <Modal.Title>
                    </Modal.Title>
                    <Modal.Body>
                        <div className="row login-body">
                            <form className="form">
                                <div className="form-group mb-3">
                                    <input type="number" onChange={this.mobileOnChange} className="form-control" autoComplete='off' placeholder="Mobile Number" />
                                </div>
                                <div className="form-group mb-3 forgot-pass-rel-div">
                                    <input type="password" onChange={this.passwordOnChange} autoComplete='off' className="form-control" placeholder="Password" />
                                    <Link to="/recover-password" className="forgot-txt create-link-txt">Forgot Password?</Link>
                                </div>
                                <div className="form-group mb-3">
                                    <span onClick={this.loginSubmit} className="btn base-button text-light d-block text-uppercase">Login</span>
                                </div>
                                <div className="form-group text-center">
                                    <span>Don’t have an account? </span>
                                    <Link to="/phone-signup" className="create-link-txt"> Create one</Link>
                                </div>
                                <div className="or or--1 mt-0 text-center">
                                    <span>Or</span>
                                </div>
                                <div className="form-group">
                                    <span className="btn base-button text-light d-block text-uppercase" onClick={() => { this.setState({ UserRedirect: "info" }) }}> Guest Checkout </span>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ border: 'none' }} className="justify-content-center">
                    </Modal.Footer>
                </Modal>
                <Header />
                <MobileHeader />
                <div className="product-cart-main my-3">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-11">
                                <div className="text-center">
                                    <h1 className="text-uppercase" style={{ fontSize: "25px" }}>Cart</h1>
                                </div>
                                <div className="cartlist-header mt-5">
                                    <div className="row">
                                        <div className="col-lg-5 col-md-5">
                                            <h2 className="" style={{ fontSize: "1rem" }}>PRODUCT</h2>
                                        </div>
                                        <div className="col-lg-3 col-md-3 text-center">
                                            <h2 className="" style={{ fontSize: "1rem" }}>QUANTITY</h2>
                                        </div>
                                        <div className="col-lg-4 col-md-4 text-end">
                                            <h2 className="" style={{ fontSize: "1rem" }}>TOTAL</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="cartlist-body">
                                    {myView}
                                </div>
                                <div className="cartlist-footer mt-3">
                                    <div className="row justify-content-end">
                                        <div className="col-md-4 text-end">
                                            <h5 className="text-uppercase">TOTAL:</h5>
                                            <h6 className="">৳{total}</h6>
                                            <p className="">Shipping & taxes calculated at checkout</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-11">
                                <div className="est-shpping-box">
                                    <h3 className="text-uppercase">Estimate and select Shipping + Handling</h3>
                                    <div className="form-group row mb-2">
                                        <div className="col-md-4">
                                            <select onChange={this.getDivision}
                                                className="form-select select-division text-uppercase"
                                                id="division">
                                                <option value="" selected disabled hidden>Select division</option>
                                                {myDivision}
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <select onChange={this.getDistrict}
                                                className="form-select select-division text-uppercase"
                                                id="district">
                                                <option value="" selected disabled hidden>Select district</option>
                                                {myDistrict}
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <input onChange={this.getPostCode} type="text" className="form-control"
                                                id="postCode" defaultValue={this.state.postCode}
                                                placeholder="POST CODE" />
                                        </div>
                                        <div className="col-md-2 d-none d-md-block">
                                            <Link onClick={this.estimateSubmit} to="#"
                                                className="btn base-button text-light d-block text-uppercase">ESTIMATE</Link>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-10">
                                            <input onChange={this.getAddress} type="text" className="form-control"
                                                id="address" defaultValue={this.state.address}
                                                placeholder="ADDRESS HERE" />
                                        </div>
                                        <div className="col-md-2 d-none d-md-block">
                                            <p className="mt-2 mb-0 dfrnt-shipping-amount">Shipping
                                                charge: </p>
                                            <p className="dfrnt-shipping-amount text-center">{this.props.estimateInfo.shippingCost ? "৳ " + this.props.estimateInfo.shippingCost : "৳ 00"}</p>
                                        </div>
                                        <span style={{ color: 'red' }} id="address-valid"></span>
                                    </div>
                                    <div className="row shipping-charge-row-gap">
                                        <div className="col-md-6">
                                            <p className="m-0 mt-2 expected-date-time"> Expected Delivery Date and
                                                Time</p>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col-md-5 inp-abs-img-div">
                                            <DatePicker autoComplete='off'
                                                className="form-control text-uppercase estimate-date-picker" id="date"
                                                placeholderText="Expected Delivery Date"
                                                selected={this.state.date}
                                                onChange={this.getDate}
                                                minDate={new Date()}
                                            />
                                        </div>
                                        <div className="col-md-5 inp-abs-img-div">
                                            <select onChange={this.getTime}
                                                className="form-select form-select-arrow text-uppercase" id="time">
                                                <option value="">Select Time</option>
                                                <option value="10.00 AM">10 : 00 AM</option>
                                                <option value="11.00 AM">11 : 00 AM</option>
                                                <option value="12.00 AM">12 : 00 AM</option>
                                                <option value="01.00 PM">01 : 00 PM</option>
                                                <option value="02.00 PM">02 : 00 PM</option>
                                                <option value="03.00 PM">03 : 00 PM</option>
                                                <option value="04.00 PM">04 : 00 PM</option>
                                                <option value="05.00 PM">05 : 00 PM</option>
                                                <option value="06.00 PM">06 : 00 PM</option>
                                                <option value="07.00 PM">07 : 00 PM</option>
                                                <option value="08.00 PM">08 : 00 PM</option>
                                                <option value="09.00 PM">09 : 00 PM</option>
                                                <option value="10.00 PM">10 : 00 PM</option>
                                            </select>
                                            <img src={clockImg} alt="clock" title="clock" className="inp-abs-img" />
                                        </div>
                                    </div>
                                    <div className="form-group row d-block d-md-none">
                                        <div className="col-md-9">
                                            {/*<p className="mt-3 mb-2"> <span className="dot">•</span>  1 option available:</p>*/}
                                            <p className="mt-2 dfrnt-shipping-amount">Shipping
                                                charge :
                                                {this.props.estimateInfo.shippingCost ? "৳" + this.props.estimateInfo.shippingCost : "  ৳ 00"}</p>
                                        </div>
                                        <span style={{ color: 'red' }} id="address-valid"></span>
                                    </div>

                                    <div className="col-md-2 d-block d-md-none">
                                        <Link onClick={this.estimateSubmit} to="#"
                                            className="btn base-button text-light d-block text-uppercase">ESTIMATE</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-11 text-end">
                                {
                                    (this.state.checkout ? this.props.accessToken ? (<span onClick={() => { this.setState({ UserRedirect: "shipping" }) }} className="btn base-button text-light text-uppercase">CHECKOUT</span>) : (<span className="btn base-button text-light text-uppercase" onClick={() => {
                                        this.loginModelShow()
                                    }}>CHECKOUT</span>) : <span className="btn disabled base-button text-light text-uppercase" title="First select estimate">CHECKOUT</span>)
                                }

                            </div>
                        </div>
                        <div className="rcnt-view-product-wrap">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="rcnt-view-prdct-title">
                                        <h2>RECENTLY VIEWED</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="rcnt-view-product">
                                <RecentViewSlider recentlyView={this.state.recentlyView} cartRec={true} />
                            </div>
                        </div>
                    </div>
                    <Toaster position="top-right" reverseOrder={false} />
                </div>

                {this.onUserRedirect()}
                {this.cartEmptyRedirect()}
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    cart: state.builder.cart,
    estimateInfo: state.builder.estimateInfo,
    cartTotalItem: state.builder.cartTotalItem,
    userInfo: state.builder.userInfo,
    guestId: state.builder.guestId,
    accessToken: state.builder.accessToken,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(ProductCart);