import React, {Fragment} from "react";
import {Route} from "react-router-dom";
import Home from "./modules/Home/Home";
import Login from "./modules/Login/Login";
import PhoneSignup from "./modules/Signup/PhoneSignup";
import RegOTPVerification from "./modules/Signup/OTPVerificationSingup";
import RegSuccess from "./modules/Signup/RegSuccess";
import RecoverPassword from "./modules/Signup/RecoverPassword";
import ProductCart from "./modules/Cart/ProductCart";
import Signup from "./modules/Signup/Signup";
import ProductDetails from "./modules/Products/ProductDetails";
import ProductCategories from "./modules/Products/ProductCategories";
import CartInfo from "./modules/Cart/CartInfo";
import CartPayment from "./modules/Cart/CartPayment";
import Product from "./modules/Home/product";
import PaymentSuccess from "./modules/Cart/components/PaymentSuccess";
import PrivacyPolicy from "./modules/Pages/PrivacyPolicy";
import TermsAndConditions from "./modules/Pages/TermsAndConditions";
import Faqs from "./modules/Pages/Faqs";
import OtpResetPassword from "./modules/Signup/OtpResetPassword";

const PublicRoutes = () => { 
    return (
        <Fragment>
            {/* <Route path="/products" component={Product}/> */}
            {/* Login */}
            <Route path="/login" component={Login}/>
            {/* SignUP */}
            <Route path="/phone-signup" component={PhoneSignup}/>
            <Route path="/reg-otp-verification" component={RegOTPVerification}/>
            <Route path="/signup" component={Signup}/>
            <Route path="/reg-success" component={RegSuccess}/>
            <Route path="/recover-password" component={RecoverPassword}/>
            <Route path="/otp-reset-password" component={OtpResetPassword}/>
            {/* Products Page */}
            <Route path="/category/:cat/:subCat?" component={ProductCategories}/>
            <Route path="/product-cart" component={ProductCart}/>
            <Route path="/product-details/:slug" component={ProductDetails}/>

            <Route path="/cart-info" component={CartInfo}/>
            <Route path="/cart-payment" component={CartPayment}/>
            <Route path="/payment-success" component={PaymentSuccess}/>
            {/* Pages */}
            <Route path="/faqs" component={Faqs}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/terms-condition" component={TermsAndConditions}/>

            <Route exact path="/" component={Product}/>
        </Fragment>
    );
}
export default PublicRoutes;