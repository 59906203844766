import React from 'react'
import {Link} from 'react-router-dom';
import cartProductImg from '../../../../dist/images/cartproductimg.png';
import chatImg from '../../../../dist/images/chat.png';
import buyerImg from '../../../../dist/images/buyer/1.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import CartProductList from '../../Cart/components/CartProductList';

const OrderDetails = () => {

    return (
        <div>
            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <h6 className="title-6">TRACK ORDER</h6>
                    <div className="row">
                        <div className="col-md-6">
                            <p className="">
                                <strong className="">INVOICE ID</strong> <br/>
                                ESM002329491
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p className="text-center">
                                <strong className="">INVOICE ID</strong> <br/>
                                ESM002329491
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="stepper d-flex flex-column mt-5 ml-2">
                            <div className="d-flex mb-1">
                                <div className="rounded-box d-flex flex-column pr-4 align-items-center">
                                    <div className="rounded-circle stepper-round bg-dark text-white mb-1">&nbsp;</div>
                                    <div className="line active-line h-100"></div>
                                </div>
                                <div>
                                    <h6 className="text-dark">ORDER PLACE</h6>
                                    <p className="lead text-muted pb-3">22 MAY 2021, 01:46 AM</p>
                                </div>
                            </div>
                            <div className="d-flex mb-1">
                                <div className="rounded-box d-flex flex-column pr-4 align-items-center">
                                    <div className="active-rounded"></div>
                                    <div className="rounded-circle stepper-round bg-dark text-white mb-1">&nbsp;</div>
                                    <div className="line h-100"></div>
                                </div>
                                <div>
                                    <h6 className="text-dark">PICKED</h6>
                                    <p className="lead text-muted pb-3">22 MAY 2021, 01:46 AM</p>
                                </div>
                            </div>
                            <div className="d-flex mb-1">
                                <div className="rounded-box d-flex flex-column pr-4 align-items-center">
                                    <div className="rounded-circle stepper-round bg-dark text-white mb-1">&nbsp;</div>
                                    <div className="line h-100"></div>
                                </div>
                                <div>
                                    <h6 className="text-dark">SHIPPED</h6>
                                    <p className="lead text-muted pb-3">22 MAY 2021, 01:46 AM</p>
                                </div>
                            </div>
                            <div className="d-flex mb-1">
                                <div className="rounded-box d-flex flex-column pr-4 align-items-center mr-2">
                                    <div className="rounded-circle stepper-round bg-dark text-white mb-1">&nbsp;</div>
                                    <div className="line h-100 d-none"></div>
                                </div>
                                <div>
                                    <h6 className="text-dark">DELIVERED</h6>
                                    <p className="lead text-muted pb-3">Estimated delivery within 3 days</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-8 col-12">
                    <div className="row">
                        <div className="col-md-6">
                            <h6 className="title-6">ORDERED FROM</h6>
                            <div className="od-product-box">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="light-gray-box text-center p-2">
                                            <img className="img-fluid" src={cartProductImg} alt="BigCo Inc. logo" title="BigCo Inc. logo"/>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <h6 className="title-6 mt-2">MAN’S SHIRT TITLE HERE NAME</h6>
                                        <div className="rating-box">
                                            <span className="p-1"><FontAwesomeIcon icon={faStar}/></span>
                                            <span className="p-1"><FontAwesomeIcon icon={faStar}/></span>
                                            <span className="p-1"><FontAwesomeIcon icon={faStar}/></span>
                                            <span className="p-1"><FontAwesomeIcon icon={faStar}/></span>
                                        </div>
                                        <p className="mb-0 mt-2">10</p>
                                        <p className="">DHAKA, BANGLADESH</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Link className="chat-shop-ownner-btn" to="#"> <img src={chatImg} alt="Cart Image" title="Cart Image"/> CHAT
                                            WITH SHOP OWNER</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h6 className="title-6 mb-3">ORDER DELIVERY DATE & TIME</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                                        <div className="time-counter text-center">
                                            <div className="counter-rounded">
                                                Today
                                            </div>
                                            <p className="">Day</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                                        <div className="time-counter text-center">
                                            <div className="counter-rounded">
                                                10
                                            </div>
                                            <p className="">Hours</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                                        <div className="time-counter text-center">
                                            <div className="counter-rounded">
                                                50
                                            </div>
                                            <p className="">Min</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-3">
                                        <div className="time-counter text-center">
                                            <div className="counter-rounded">
                                                33
                                            </div>
                                            <p className="">Sec</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h6 className="text-uppercase">BILL To</h6>
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-4 col-3">
                                    <img className="img-fluid rounded-circle" src={buyerImg} alt="Buyer Image" title="Buyer Image"/>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-8 col-9">
                                    <div className="right-side-info">
                                        <h6 className="buyer-name text-uppercase">MD RAFEZ HOSSAIN</h6>
                                        <p className="">01682314726</p>
                                        <p className="">F16, Flat 4A, Road 1, Mohammadpur, Dhaka
                                            Bangladesh</p>
                                        <p className="">Contact No: 01978085756</p>
                                        <h6 className="buyerlv-add-title">Delivery Address</h6>
                                        <p className="">F16, Flat 4A, Road 1, Mohammadpur,
                                            Dhaka Bangladesh</p>
                                        <Link className="refund-req-link btn btn-outline-dark px-5" to="">REFUND
                                            REQUEST</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-12">
                    <h5 className="title-5">PRODUCT DESCRIPTION</h5>
                    <CartProductList/>
                </div>
            </div>
        </div>
    )
}

export default OrderDetails;
