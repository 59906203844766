import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import ApiUrl from "../api/ApiUrl";
import PublicRoutes from "./PublicRoutes";
import { AnimatedSwitch } from "react-router-transition";
import {connect} from "react-redux";
import {eshop} from "../redux/reducers";

const Routes = (props) => {
    // console.log(props.accessToken)
    return (
        <Router>
            <Switch>
                <AnimatedSwitch
                    atEnter={{ opacity: 1 }}
                    atLeave={{ opacity: 1 }}
                    atActive={{ opacity: 1 }}
                    className="switch-wrapper"
                >
                    {props.accessToken ? ( <Fragment>
                        <PrivateRoutes />
                      { /*  <PublicRoutes /> */}
                    </Fragment>):(<Fragment><PublicRoutes /></Fragment>)}
                </AnimatedSwitch>
            </Switch>
        </Router>
    );
}

const mapStateToProps = state => ({
    accessToken: state.builder.accessToken,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(Routes);
