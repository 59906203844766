import ReactHtmlParser, {  } from 'react-html-parser';

class LocalStorageHelper {
    static setUserMobile(userMobile){
        localStorage.setItem("mobile", userMobile)
    }
    static getUserMobile(){
        return  localStorage.getItem("mobile")
    }
    static removeUserMobile(){
        return  localStorage.removeItem("mobile")
    }
    static setOptCode(otpCode){
        localStorage.setItem("otpCode", otpCode)
    }
    static getOptCode(){
       return localStorage.getItem("otpCode")
    }
    static loadingBtn(){
       return ReactHtmlParser('Processing <div className="spinner-border text-success process-loader" role="status"> </div>')
    }
    static setAccessToken(access_token){
        localStorage.setItem("access_token", access_token)
    }
    // local Storage
    static sessionId(pre){
        return pre+'_' + Math.random().toString(36).substr(2, 9);
    }
    // local Storage
    static setItem(kye, value){
        localStorage.setItem(kye, value)
    }
    static getItem(key){
        return localStorage.getItem(key)
    }
    // session Storage
    static sessionSetItem(kye, value){
        sessionStorage.setItem(kye, value)
    }
    static sessionGetItem(key){
        return sessionStorage.getItem(key)
    }
}

export default LocalStorageHelper;