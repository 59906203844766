import React from 'react'
import Header from '../../Header/Header';
import MobileHeader from '../../Header/MobileHeader';
import Footer from '../../Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import LocalStorageHelper from "../../../../LocalStorageHelper/LocalStorageHelper";
import { Link } from 'react-router-dom';

const PaymentSuccess = () => {
    return (
        <div>
            <Header />
            <MobileHeader />
            <div className="container">
                <div className="row">
                    <div className="col-md-6 mx-auto">
                        <div className="Successful-payment-main text-center">
                            <div className="success-payment-text">
                                <p>Your order has been placed</p>
                            </div>
                            <p className="payment-success-order-id"> Order Id : {LocalStorageHelper.getItem("orderId")}</p>
                            <div className="success-payment-icon">
                                <span><FontAwesomeIcon icon={faCheck} /></span>
                            </div>
                            <div className="back-home-btn-wrap">
                                <Link className="back-home-btn" to="/"> Go to Home </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PaymentSuccess
