import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ApiUrl from "../../../../api/ApiUrl";
import {Link} from "react-router-dom";

function RecentViewSlider(props) {
    const {recentlyView} = props;
    const {cartRec} = props;
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true
                }
            }
        ]
    };

    return (
        <div>
            <div className="row">
                <Slider {...settings}>
                    {recentlyView.length >= 0 ? (recentlyView && recentlyView.map((dt, i) => {
                        return (
                            <div key={i}>

                                {cartRec ? 
                                    <Link to={`/product-details/${dt.slug}`}>
                                        <div key={i} className="single-smlr-product single-product-wrap">
                                            <div className="rcnt-view-prdct-img single-product-img-wrap">
                                                {dt.current_stock === 0 ? (<span className="slider-product-stock">Stock Out</span>) : ""}
                                                <img src={ApiUrl.showImage(dt.thumbnail_image)} alt={dt.name}/>
                                            </div>
                                            <div className="rcnt-view-prdct-details">
                                                <div className="rcnt-view-prdct-name">
                                                    <p>{dt.name}</p>
                                                </div>
                                                <div className="rcnt-view-prdct-price">
                                                    <p className="product-price mb-0">{dt.base_discounted_price !== dt.base_price ? (<div><del>৳ {dt.base_price}</del> ৳ {dt.base_discounted_price}</div>) : (<div>৳ {dt.base_price}</div>)}</p>
                                                </div>
                                            </div>
                                    </div> 
                                    </Link>:<Link to={`/product-details/${dt.slug}`}><div onClick={() => props.setSlug(dt.slug)} key={i} className="single-smlr-product single-product-wrap">
                                    <div className="rcnt-view-prdct-img single-product-img-wrap">
                                        {dt.current_stock === 0 ? (<span className="slider-product-stock">Stock Out</span>) : ""}
                                        <img src={ApiUrl.showImage(dt.thumbnail_image)} alt={dt.name}/>
                                    </div>
                                    <div className="rcnt-view-prdct-details">
                                        <div className="rcnt-view-prdct-name">
                                            <p>{dt.name}</p>
                                        </div>
                                        <div className="rcnt-view-prdct-price">
                                            <p className="product-price mb-0">{dt.base_discounted_price !== dt.base_price ? (<div><del style={{marginRight: "5px", color: "#f00"}}>৳ {dt.base_price}</del> <span style={{fontWeight: "600"}}>৳ {dt.base_discounted_price}</span></div>) : (<div>৳ {dt.base_price}</div>)}</p>
                                        </div>
                                    </div>
                                </div></Link>}
                            </div>
                        )
                    })) : (<h2/>)}
                </Slider>
            </div>
        </div>

    );
}

RecentViewSlider.defaultProps = {
    recentlyView: []
};
export default RecentViewSlider;
