import React from 'react';

const SizeChart = (props) => {
    const choiceOptions = props.data

    return (
        <div>
            <div className="accordion-item">
                <h2 className="accordion-header">
                    <button className="accordion-button collapsed description-title" type="button"
                            data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                        SIZE CHART
                    </button>
                </h2>
                <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                    <div className="accordion-body">
                        <div className="product-size-chart">
                            {
                                choiceOptions.map((choice, i) => {
                                    return (
                                        <div key={i} className="size-chart-title">
                                            <span>{choice.title} :</span>
                                            {choice.values.map((value, i) => {
                                                return (<p key={i}>{value}</p>)
                                            })}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SizeChart;
