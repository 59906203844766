import React, {useEffect, useState} from 'react';
import {Link, Redirect, useLocation} from "react-router-dom";
import searchImg from "../../../dist/images/search1.png";
import crossImg from "../../../dist/images/cross.png";
import logoImg from "../../../dist/images/logo.png";
import { toast } from "react-hot-toast";
import ApiUrl from "../../../api/ApiUrl";
import Axios from "axios"
import { eshop } from "../../../redux/reducers";
import { connect } from "react-redux";


const Header = (props) => {
    const [display, setDisplay] = useState(false);
    const [token] = useState(props.accessToken);
    const [searchSuggetion, setSearchSuggetion] = useState(false);
    const [searchProduct, setSearchProduct] = useState([]);
    const [checkRedirect, setCheckRedirect] = useState(false);

    const location = useLocation();
    let cart = props.cart;


    const productSearch = (event) => {
        let productName = event.target.value;
        if (productName.length > 1) {
            let myFormData = new FormData();
            myFormData.append("productName", productName);
            Axios.post(ApiUrl.SearchProduct, myFormData).then((response) => {
                if (response.status === 200) {
                    setSearchProduct(response.data.data)
                }
            }).catch(function () {
                toast.error("Request failed ! try again");
            })
        } else {
            setSearchProduct([])
        }
    }
    const logOut = () => {
        props.setUserInfo({});
        props.setShippingAddress({});
        props.setAccessToken()
    }
    const login = () =>{
        props.setPreviousUrl(window.location.pathname);
        setCheckRedirect(true)
        onUserRedirect()
    }
    const onUserRedirect = () =>{
        if(checkRedirect == true){
            return <Redirect to='/login'  />
        }
    }

    return (
        <>
            <header className={location.pathname === "/" ? "header-border d-none d-sm-block desktop-header" :"d-none d-sm-block desktop-header"}>
                <nav className="navbar">
                    <div className="container">
                        <Link className="navbar-brand text-light header-logo"
                            to={location.pathname === "/" ? "/" : "/"} style={{ fontSize: "25px" }}>
                            <img src={logoImg} alt="Logo" title="Logo" />
                        </Link>
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item dropdown">
                                <div className="nav-link text-uppercase dropdown-toggle show" onClick={() => { setDisplay(!display) }} style={{ cursor: "pointer" }}>search</div>
                            </li>

                            <li className="nav-item dropdown">
                                {cart.length > 0 ? <Link className="nav-link text-uppercase" to="/product-cart">
                                    Cart({cart.length ? cart.length : "0"})
                                </Link> : <span className="nav-link text-uppercase">
                                    Cart({cart.length ? cart.length : "0"})
                                </span>
                                }
                            </li>

                            <li className="nav-item dropdown">

                                <Link to="#" className="nav-link text-uppercase dropdown-toggle" type="button"
                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Account
                                </Link>
                                <ul className="dropdown-menu btn-body" aria-labelledby="dropdownMenuButton1">
                                    {
                                        props.accessToken ?
                                            (<div>
                                                <li><Link className="dropdown-item" to="/buyer-profile">My Panel</Link></li>
                                                <li><Link to="/" onClick={logOut} className="dropdown-item">Logout</Link></li>
                                            </div> ):
                                            (<div>

                                                {window.location.pathname === "/cart-info" || window.location.pathname === "/cart-payment" ? (<span>
                                                    <li><Link className="dropdown-item" to="/phone-signup">Sign Up</Link></li>
                                                    <li><span className="dropdown-item d-none" onClick={login}>Sign In </span></li>
                                                </span>): (<span>
                                                    <li><Link className="dropdown-item" to="/phone-signup">Sign Up</Link></li>
                                                    <li><span className="dropdown-item" onClick={login}>Sign In </span></li>
                                                </span>)}

                                            </div>)
                                    }
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            {display &&
                <div className={location.pathname === "/" && "search-bg"}>
                    <div className="container">
                        <div className="header-search-box-main">
                            <div className="search-product">
                                <input type="text" onFocus={() => {
                                    setSearchSuggetion(true);
                                }} onKeyUp={productSearch} className="form-control" placeholder="SEARCH..." />
                                {
                                    searchSuggetion && <ul className="results">
                                        {
                                            searchProduct.map((value, i) => {
                                                return (<li key={i}><Link target="_blank"
                                                    to={`/product-details/${value.slug}`}>{value.name}</Link>
                                                </li>)
                                            })
                                        }
                                    </ul>
                                }
                                <span onClick={() => { setDisplay(!display) }} className="input-group-text custom-cross-btn"><img src={crossImg}
                                    alt="Cross" title="Cross" /></span>
                                <span className="custom-search-btn"><img src={searchImg} alt="search" title="search" /></span>
                            </div>
                        </div>
                    </div>
                </div>}
            {onUserRedirect()}
                </>
    );
}
const mapStateToProps = state => ({
    cart: state.builder.cart,
    cartTotalItem: state.builder.cartTotalItem,
    accessToken: state.builder.accessToken,
    guestId: state.builder.guestId,
    userInfo: state.builder.userInfo,
    previousUrl: state.builder.previousUrl,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(Header);