import React, {useState} from 'react';
import Axios from "axios";
import ApiUrl from "../../../api/ApiUrl";
import toast from "react-hot-toast";
import {connect} from "react-redux";
import {eshop} from "../../../redux/reducers";

const ChangePass = ({refreshPage, userInfo, primaryAddressData}) => {
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const getCurrentPassword = (event) => {
        setCurrentPassword(event.target.value)
    }
    const getNewPassword = (event) => {
        setNewPassword(event.target.value)
    }
    const getConfirmPassword = (event) => {
        setConfirmPassword(event.target.value)
    }
    const changePassword = () => {
        let myFormData = new FormData();
        myFormData.append("user_id", userInfo.id);
        myFormData.append("current_password", currentPassword);
        myFormData.append("new_password", newPassword);
        myFormData.append("password_confirm", confirmPassword);
        Axios.post(ApiUrl.ChangePassword, myFormData).then((response) => {
            if (response.status === 200 && response.data.status === 1) {
                for (let success of response.data.message) {
                    toast.success(success)
                    refreshPage();
                }
            } else if (response.status === 200 && response.data.status === 0) {
                for (let error of response.data.message) {
                    toast.error(error)
                }
            }
        }).catch(function () {
            toast.error("Request failed ! try again");
        })
    }
    return (
        <div id="BuyerChangePass">
            <div className="buyer-profile-wrap mt-0">
                <div className="buyer-profile-main">
                    <div className="row">
                        <div className="col-md-9 mt-2">
                            <div className="buyer-profile-details">
                                <div className="row">
                                    <div className="col-md-6">
                                        <input onChange={getCurrentPassword} type="password" className="box-width mt-4"
                                               placeholder="  CURRENT PASSWORD"/>
                                        <input onChange={getNewPassword} type="password" className="box-width"
                                               placeholder="  NEW PASSWORD"/>
                                        <input onChange={getConfirmPassword} type="password" className="box-width"
                                               placeholder="  CONFIRM NEW PASSWORD"/>
                                        <div className="edit-profile-btn text-center">
                                            <div className="update-profile-btn">
                                                <button onClick={changePassword}
                                                        className="edit-profile-update"> Update
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="buyer-other-info">
                                <div className="other-info-title">
                                    <h5>PRIMARY ADDRESS</h5>
                                </div>
                                <div className="other-info-detail">
                                    {primaryAddressData.id ? (<div><p className="other-info-name">{primaryAddressData?.firstName} {primaryAddressData?.lastName}</p><p>{primaryAddressData?.address}, {primaryAddressData?.divisionId + ","} {primaryAddressData?.districtId } {"-" + primaryAddressData?.postal_code} </p></div>) : " "}
                                </div>
                                <div className="edit-address-btn">
                                    <button className="buyer-address-edit"> EDIT addresses</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    userInfo: state.builder.userInfo,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(ChangePass);