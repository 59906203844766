import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import React from "react";
import './Loader.css';

class Loader {
    static homeCategory(){
        const skeletonStyles = {

        }
        const skeleton__Profile = {

        }
        const skeleton__Profile__info = {
            display: "block",
            textAlign: "center",
            padding: "0.3rem 0"
        }
        const skeleton__Profile__info_title = {
            marginBottom: "1px"
        }
        return (
            <div className="row mt-3">
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
            </div>
        )
    }
    static homeSlider() {
        const skeletonStyles = {
            marginRight: "10px",
            textAlign: "start",
            padding: "5px"
        }
        const skeleton__Profile = {
            display: "flex"
        }
        const skeleton__Profile__info = {
            display: "grid",
            gridTemplateColumns: "min-content",
            alignItems: "center"
        }
        return (
            <>
                <div style={skeletonStyles}>
                    <SkeletonTheme>
                        <div >
                            <Skeleton
                                className="home__banner__skeleton"
                                count={1}
                                duration={2}
                                delay={2}
                            />
                        </div>
                        <div style={skeleton__Profile}>
                        </div>
                    </SkeletonTheme>
                </div>
            </>
        )
    }
    static homeLoader() {
        const skeletonStyles = {

        }
        const skeleton__Profile = {

        }
        const skeleton__Profile__info = {
            display: "block",
            textAlign: "center",
            padding: "1rem 0"
        }
        const skeleton__Profile__info_title = {
            marginBottom: "3px"
        }
        return (
            <div className="row mt-3">
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                <div style={skeletonStyles}>
                    <SkeletonTheme>
                        <div>
                            <Skeleton
                                className="home__detail__img__skeleton"
                                count={1}
                                duration={2}
                                delay={2}
                            />
                        </div>
                        <div style={skeleton__Profile}>
                            <div style={skeleton__Profile__info}>
                                <div style={skeleton__Profile__info_title}>
                                    <Skeleton
                                        className="home__detail__title__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                                <Skeleton
                                    className="home__detail__price__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={1}
                                />
                            </div>
                        </div>
                    </SkeletonTheme>
                </div>
            </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                <div style={skeletonStyles}>
                    <SkeletonTheme>
                        <div>
                            <Skeleton
                                className="home__detail__img__skeleton"
                                count={1}
                                duration={2}
                                delay={2}
                            />
                        </div>
                        <div style={skeleton__Profile}>
                            <div style={skeleton__Profile__info}>
                                <div style={skeleton__Profile__info_title}>
                                    <Skeleton
                                        className="home__detail__title__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                                <Skeleton
                                    className="home__detail__price__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={1}
                                />
                            </div>
                        </div>
                    </SkeletonTheme>
                </div>
            </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
            </div>
        )
    }
    static categoryProduct() {
        const skeletonStyles = {

        }
        const skeleton__Profile = {

        }
        const skeleton__Profile__info = {
            display: "block",
            textAlign: "center",
            padding: "1rem 0"
        }
        const skeleton__Profile__info_title = {
            marginBottom: "3px"
        }
        return (
            <div className="row mt-3">
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                    <div style={skeletonStyles}>
                        <SkeletonTheme>
                            <div>
                                <Skeleton
                                    className="home__detail__img__skeleton"
                                    count={1}
                                    duration={2}
                                    delay={2}
                                />
                            </div>
                            <div style={skeleton__Profile}>
                                <div style={skeleton__Profile__info}>
                                    <div style={skeleton__Profile__info_title}>
                                        <Skeleton
                                            className="home__detail__title__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                    <Skeleton
                                        className="home__detail__price__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={1}
                                    />
                                </div>
                            </div>
                        </SkeletonTheme>
                    </div>
                </div>
            </div>
        )
    }
    static newArrival() {
        const skeletonStyles = {

        }
        const skeleton__Profile = {

        }
        const skeleton__Profile__info = {
            display: "block",
            textAlign: "center",
            padding: "1rem 0"
        }
        const skeleton__Profile__info_title = {
            marginBottom: "3px"
        }
        return (
            <div className="Row">
               <div className="col-12">
                   <div className="col-lg-3 col-md-3 col-sm-3 col-6 float-end">
                       <div style={skeletonStyles}>
                           <SkeletonTheme>
                               <div>
                                   <Skeleton
                                       className="home__detail__img__skeleton"
                                       count={1}
                                       duration={2}
                                       delay={2}
                                   />
                               </div>
                               <div style={skeleton__Profile}>
                                   <div style={skeleton__Profile__info}>
                                       <div style={skeleton__Profile__info_title}>
                                           <Skeleton
                                               className="home__detail__title__skeleton"
                                               count={1}
                                               duration={2}
                                               delay={1}
                                           />
                                       </div>
                                       <Skeleton
                                           className="home__detail__price__skeleton"
                                           count={1}
                                           duration={2}
                                           delay={1}
                                       />
                                   </div>
                               </div>
                           </SkeletonTheme>
                       </div>
                   </div>
                   <div className="col-lg-3 col-md-3 col-sm-3 col-6 float-end">
                       <div style={skeletonStyles}>
                           <SkeletonTheme>
                               <div>
                                   <Skeleton
                                       className="home__detail__img__skeleton"
                                       count={1}
                                       duration={2}
                                       delay={2}
                                   />
                               </div>
                               <div style={skeleton__Profile}>
                                   <div style={skeleton__Profile__info}>
                                       <div style={skeleton__Profile__info_title}>
                                           <Skeleton
                                               className="home__detail__title__skeleton"
                                               count={1}
                                               duration={2}
                                               delay={1}
                                           />
                                       </div>
                                       <Skeleton
                                           className="home__detail__price__skeleton"
                                           count={1}
                                           duration={2}
                                           delay={1}
                                       />
                                   </div>
                               </div>
                           </SkeletonTheme>
                       </div>
                   </div>
                   <div className="col-lg-3 col-md-3 col-sm-3 col-6 float-end">
                       <div style={skeletonStyles}>
                           <SkeletonTheme>
                               <div>
                                   <Skeleton
                                       className="home__detail__img__skeleton"
                                       count={1}
                                       duration={2}
                                       delay={2}
                                   />
                               </div>
                               <div style={skeleton__Profile}>
                                   <div style={skeleton__Profile__info}>
                                       <div style={skeleton__Profile__info_title}>
                                           <Skeleton
                                               className="home__detail__title__skeleton"
                                               count={1}
                                               duration={2}
                                               delay={1}
                                           />
                                       </div>
                                       <Skeleton
                                           className="home__detail__price__skeleton"
                                           count={1}
                                           duration={2}
                                           delay={1}
                                       />
                                   </div>
                               </div>
                           </SkeletonTheme>
                       </div>
                   </div>
                   <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                       <div style={skeletonStyles}>
                           <SkeletonTheme>
                               <div>
                                   <Skeleton
                                       className="home__detail__img__skeleton"
                                       count={1}
                                       duration={2}
                                       delay={2}
                                   />
                               </div>
                               <div style={skeleton__Profile}>
                                   <div style={skeleton__Profile__info}>
                                       <div style={skeleton__Profile__info_title}>
                                           <Skeleton
                                               className="home__detail__title__skeleton"
                                               count={1}
                                               duration={2}
                                               delay={1}
                                           />
                                       </div>
                                       <Skeleton
                                           className="home__detail__price__skeleton"
                                           count={1}
                                           duration={2}
                                           delay={1}
                                       />
                                   </div>
                               </div>
                           </SkeletonTheme>
                       </div>
                   </div>
               </div>
            </div>
        )
    }
    static upComming() {
        const skeletonStyles = {}
        const skeleton__Profile = {}
        const skeleton__Profile__info = {
            display: "block",
            textAlign: "center",
            padding: "1rem 0"
        }
        const skeleton__Profile__info_title = {
            marginBottom: "3px"
        }
        return (
            <div className="Row">
                <div className="col-12">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-6 float-end">
                        <div style={skeletonStyles}>
                            <SkeletonTheme>
                                <div>
                                    <Skeleton
                                        className="home__detail__img__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={2}
                                    />
                                </div>
                                <div style={skeleton__Profile}>
                                    <div style={skeleton__Profile__info}>
                                        <div style={skeleton__Profile__info_title}>
                                            <Skeleton
                                                className="home__detail__title__skeleton"
                                                count={1}
                                                duration={2}
                                                delay={1}
                                            />
                                        </div>
                                        <Skeleton
                                            className="home__detail__price__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                </div>
                            </SkeletonTheme>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-6 float-end">
                        <div style={skeletonStyles}>
                            <SkeletonTheme>
                                <div>
                                    <Skeleton
                                        className="home__detail__img__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={2}
                                    />
                                </div>
                                <div style={skeleton__Profile}>
                                    <div style={skeleton__Profile__info}>
                                        <div style={skeleton__Profile__info_title}>
                                            <Skeleton
                                                className="home__detail__title__skeleton"
                                                count={1}
                                                duration={2}
                                                delay={1}
                                            />
                                        </div>
                                        <Skeleton
                                            className="home__detail__price__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                </div>
                            </SkeletonTheme>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-6 float-end">
                        <div style={skeletonStyles}>
                            <SkeletonTheme>
                                <div>
                                    <Skeleton
                                        className="home__detail__img__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={2}
                                    />
                                </div>
                                <div style={skeleton__Profile}>
                                    <div style={skeleton__Profile__info}>
                                        <div style={skeleton__Profile__info_title}>
                                            <Skeleton
                                                className="home__detail__title__skeleton"
                                                count={1}
                                                duration={2}
                                                delay={1}
                                            />
                                        </div>
                                        <Skeleton
                                            className="home__detail__price__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                </div>
                            </SkeletonTheme>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-6">
                        <div style={skeletonStyles}>
                            <SkeletonTheme>
                                <div>
                                    <Skeleton
                                        className="home__detail__img__skeleton"
                                        count={1}
                                        duration={2}
                                        delay={2}
                                    />
                                </div>
                                <div style={skeleton__Profile}>
                                    <div style={skeleton__Profile__info}>
                                        <div style={skeleton__Profile__info_title}>
                                            <Skeleton
                                                className="home__detail__title__skeleton"
                                                count={1}
                                                duration={2}
                                                delay={1}
                                            />
                                        </div>
                                        <Skeleton
                                            className="home__detail__price__skeleton"
                                            count={1}
                                            duration={2}
                                            delay={1}
                                        />
                                    </div>
                                </div>
                            </SkeletonTheme>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    static productDetailLoader() {
        const skeletonStyles = {
            textAlign: "start",
            padding: "5px"
        }
        const productDetail__main = {
        }
        const productDetail__img = {

        }
        return (
            <>
                <div style={skeletonStyles}>
                    <SkeletonTheme>
                        <div className="container">
                            <div style={productDetail__main}>
                                <div className="row">
                                    <div className="col-md-7">
                                        <div style={productDetail__img}>
                                            <Skeleton
                                                count={1}
                                                duration={2}
                                                delay={2}
                                                width="100%"
                                                height={422}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="productDetail__content">
                                            <div className="productDetail__title__skeleton">
                                                <Skeleton
                                                    count={1}
                                                    duration={2}
                                                    delay={1}
                                                    width={200}
                                                    height={25}
                                                />
                                            </div>
                                            <div className="productDetail__price__skeleton">
                                                <Skeleton
                                                    count={1}
                                                    duration={2}
                                                    delay={1}
                                                    width={100}
                                                    height={20}
                                                />
                                            </div>
                                            <div style={{ marginBottom: "1rem" }}>
                                                <Skeleton
                                                    count={1}
                                                    duration={2}
                                                    delay={1}
                                                    width="100%"
                                                    height={41}
                                                />
                                            </div>
                                            <div style={{ marginBottom: "1rem" }}>
                                                <Skeleton
                                                    count={1}
                                                    duration={2}
                                                    delay={1}
                                                    width="100%"
                                                    height={41}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div>
                                                        <Skeleton
                                                            className="productDetail__qunty__skeleton"
                                                            count={1}
                                                            duration={2}
                                                            delay={1}
                                                            width="100%"
                                                            height={41}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div>
                                                        <Skeleton
                                                            count={1}
                                                            duration={2}
                                                            delay={1}
                                                            width="100%"
                                                            height={41}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: "35px" }}>
                                                <Skeleton
                                                    count={1}
                                                    duration={2}
                                                    delay={1}
                                                    width="100%"
                                                    height={41}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-7">
                                        <div style={{ marginTop: "3rem" }}>
                                            <Skeleton
                                                count={1}
                                                duration={2}
                                                delay={1}
                                                width="100%"
                                                height={57}
                                            />
                                        </div>
                                        <div style={{ marginTop: "12px" }}>
                                            <Skeleton
                                                count={1}
                                                duration={2}
                                                delay={1}
                                                width="100%"
                                                height={57}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </SkeletonTheme>
                </div>
            </>
        )
    }
}

export default Loader