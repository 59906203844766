import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Header/Header';
import MobileHeader from '../Header/MobileHeader';
import Footer from '../Footer/Footer';

class RegSuccess extends Component{
    render(){
        return(
            <div>
                <Header />
                <MobileHeader />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-7 col-sm-10 col-12 mx-auto py-5">
                            <div className="login-body pb-5 pt-sm-5 my-sm-5 reg-success-body-mobile-view">
                                <h1 className="title-h3 text-center text-uppercase">congratulations</h1>
                                <p className="text-center my-3">Your sign up is successful</p>
                                <div className="form-group">
                                    <Link to="/" className="btn base-button text-light d-block text-uppercase">Go to home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default RegSuccess;