import React from 'react'

const PromoCode = ({ promoCodeInfo, promoCode }) => {
    return (
        <div>
            <div className="">
                <div className="promo-code-wrap mt-4">
                    <div className="promo-code-title">
                        <div className="row">
                            <div className="col-md-3">
                                <p>Serial No.</p>
                            </div>
                            <div className="col-md-9">
                                <p className="prmo-title-name">Promo Code</p>
                            </div>
                        </div>
                    </div>
                    <div className="promo-code-body">
                        {
                            promoCodeInfo.length > 0 ? (promoCodeInfo.map((value, i) => {
                                {
                                    return (
                                        <table key={i}>
                                            <tr>
                                                <td>{i + 1}</td>
                                                <td>{value.code}</td>
                                            </tr>
                                        </table>
                                    )
                                }
                            })) : (<h4 className="non-promo-msg">Your Promo Code is not Available!</h4>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromoCode;