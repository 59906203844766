import React, { useState } from 'react';
import { Link, Redirect, useLocation } from "react-router-dom";
import cartImg from "../../../dist/images/cart.png";
import searchImg from "../../../dist/images/search.png";
import searchImg1 from "../../../dist/images/search1.png";
import userImg from "../../../dist/images/user.png";
import crossImg from "../../../dist/images/cross.png";
import LocalStorageHelper from '../../../LocalStorageHelper/LocalStorageHelper';
import Burger from './components/Burger';
import { toast } from "react-hot-toast";
import ApiUrl from "../../../api/ApiUrl";
import Axios from "axios"
import { eshop } from "../../../redux/reducers";
import { connect } from "react-redux";


const MobileHeader = (props) => {
    const [token] = useState(props.accessToken);
    const [searchSuggetion, setSearchSuggetion] = useState(false)
    const [display, setDisplay] = useState(false);
    const [searchProduct, setSearchProduct] = useState([]);
    const [checkRedirect, setCheckRedirect] = useState(false);

    const location = useLocation();
    let cart = props.cart;

    const productSearch = (event) => {
        let productName = event.target.value;
        if (productName.length > 1) {
            let myFormData = new FormData();
            myFormData.append("productName", productName);
            Axios.post(ApiUrl.SearchProduct, myFormData).then((response) => {
                if (response.status === 200) {
                    setSearchProduct(response.data.data)
                }
            }).catch(function () {
                toast.error("Request failed ! try again");
            })
        } else {
            setSearchProduct([])
        }
    }

    const logOut = () => {
        props.setUserInfo({});
        props.setShippingAddress({});
        props.setAccessToken()
    }
    const login = () => {
        props.setPreviousUrl(window.location.pathname);
        setCheckRedirect(true)
        onUserRedirect()
    }
    const onUserRedirect = () => {
        if (checkRedirect == true) {
            return <Redirect to='/login' />
        }
    }

    return (
        <>
            <header className="header-border d-block d-sm-none mobile-header" >
                <nav className="navbar">
                    <div className="container">
                        <Burger />
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item dropdown">
                                <div className="nav-link text-uppercase dropdown-toggle show" onClick={() => { setDisplay(!display) }} style={{ cursor: "pointer" }}><img src={searchImg} alt="Search" title="Search" /></div>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link text-uppercase dropdown-toggle" to="/product-cart">
                                    <img src={cartImg} alt="cart" title="cart" /><span style={{
                                        position: 'absolute',
                                        top: '4px',
                                        color: '#fff'
                                    }}>{cart.length ? cart.length : "0"}</span>
                                </Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link text-uppercase dropdown-toggle" type="button"
                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={userImg} alt="user" title="user" />
                                </Link>
                                <ul className="dropdown-menu btn-body" aria-labelledby="dropdownMenuButton1">
                                    {
                                        props.accessToken ?
                                            (<div>
                                                <li><Link className="dropdown-item" to="/buyer-profile">My Panel</Link></li>
                                                <li><Link to="/" onClick={logOut} className="dropdown-item">Logout</Link></li>
                                            </div>) :
                                            (<div>

                                                {window.location.pathname === "/cart-info" || window.location.pathname === "/cart-payment" ? (<span>
                                                    <li><Link className="dropdown-item" to="/phone-signup">Sign Up</Link></li>
                                                    <li><span className="dropdown-item d-none" onClick={login}>Sign In </span></li>
                                                </span>) : (<span>
                                                    <li><Link className="dropdown-item" to="/phone-signup">Sign Up</Link></li>
                                                    <li><span className="dropdown-item" onClick={login}>Sign In </span></li>
                                                </span>)}

                                            </div>)
                                    }
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            {display &&
                <div className={location.pathname === "/" && "search-bg"}>
                    <div className="container">
                        <div className="header-search-box-main">
                            <div className="search-product">
                                <input type="text" onFocus={() => {
                                    setSearchSuggetion(true);
                                }} onKeyUp={productSearch} className="form-control" placeholder="SEARCH..." />
                                {
                                    searchSuggetion && <ul className="results">
                                        {
                                            searchProduct.map((value, i) => {
                                                return (<li key={i}><Link target="_blank"
                                                    to={`/product-details/${value.slug}`}>{value.name}</Link>
                                                </li>)
                                            })
                                        }
                                    </ul>
                                }
                                <span onClick={() => { setDisplay(!display) }} className="input-group-text custom-cross-btn"><img src={crossImg}
                                    alt="cross" title="cross" /></span>
                                <span className="custom-search-btn"><img src={searchImg1} alt="search" title="search" /></span>
                            </div>
                        </div>
                    </div>
                </div>}
            {onUserRedirect()}
        </>
    );
}

const mapStateToProps = state => ({
    cart: state.builder.cart,
    cartTotalItem: state.builder.cartTotalItem,
    accessToken: state.builder.accessToken,
    guestId: state.builder.guestId,
    userInfo: state.builder.userInfo,
    previousUrl: state.builder.previousUrl,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(MobileHeader);