import React, { Fragment, useEffect, useState } from 'react'
import { Modal } from "react-bootstrap";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ApiUrl from "../../../../api/ApiUrl";
import Axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import LocalStorageHelper from "../../../../LocalStorageHelper/LocalStorageHelper";
import {connect} from "react-redux";
import {eshop} from "../../../../redux/reducers";


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
            padding: '15px',
        },
    },
}));


const AddNewAddress =  ({accessToken, userInfo, addAddress, addAddressShow, handleClose, addressList }) => {
    const [token] = useState(accessToken);
    const classes = useStyles();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [otherAddress, setOtherAddress] = useState("");
    const [division, setDivision] = useState([]);
    const [divisionId, setDivisionId] = useState("");
    const [district, setDistrict] = useState([]);
    const [districtId, setDistrictId] = useState("");
    const [postalCode, setPostalCode] = useState("");

    useEffect(() => {
        Axios.get(ApiUrl.Division).then(response => {
            if (response.status === 200) {
                setDivision(response.data.data)
            }
        }).catch(error => {
            toast.error("Request Failde ! Try Again");
        })
    }, [])

    const getFirstName = (event) => {
        setFirstName(event.target.value)
        if (firstName.length === 0) {
            document.getElementById("firstName").style.border = "";
        }
    }
    const getLastName = (event) => {
        setLastName(event.target.value)
        if (lastName.length === 0) {
            document.getElementById("lastName").style.border = "";
        }
    }
    const getPhoneNumber = (event) => {
        setPhoneNumber(event.target.value)
        if (phoneNumber.length === 0) {
            document.getElementById("phoneNumber").style.border = "";
        }
    }
    const getAddress = (event) => {
        setAddress(event.target.value)
        if (address.length === 0) {
            document.getElementById("address").style.border = "";
        }
    }
    const getOtherAddress = (event) => {
        setOtherAddress(event.target.value)
        if (otherAddress.length === 0) {
            document.getElementById("otherAddress").style.border = "";
        }
    }
    const getDivision = (event) => {
        setDivisionId(event.target.value)
        divisions(event.target.value)
        if (event.target.value) {
            document.getElementById("division").style.border = ""
        }
    }
    const divisions = (divisionId) => {
        Axios.get(ApiUrl.District(divisionId)).then(response => {
            if (response.status === 200) {
                setDistrict(response.data.data)
            }
        }).catch(error => {
            toast.error("Request Failde ! Try Again");
        })
    }
    const getDistrict = (event) => {
        setDistrictId(event.target.value)
        if (event.target.value) {
            document.getElementById("district").style.border = ""
        }
    }
    const getPostalCode = (event) => {
        setPostalCode(event.target.value)
        if (postalCode.length === 0) {
            document.getElementById("postalCode").style.border = "";
        }
    }
    const addShippingAddress = () => {
        let customerId = userInfo.id
        if (firstName.length === 0 || lastName.length === 0 || phoneNumber.length === 0 || address.length === 0 || divisionId.length === 0 || districtId.length === 0 ||postalCode.length === 0) {
            if (firstName === "") {
                document.getElementById("firstName").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (lastName === "") {
                document.getElementById("lastName").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (phoneNumber === "") {
                document.getElementById("phoneNumber").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (address === "") {
                document.getElementById("address").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (divisionId === "") {
                document.getElementById("division").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (districtId === "") {
                document.getElementById("district").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (postalCode === "") {
                document.getElementById("postalCode").style = `border: 2px solid #f00; margin-top: -6px`;
            }
        } else {
            let myFormData = new FormData();
            myFormData.append("customer_id", customerId);
            myFormData.append("firstName", firstName);
            myFormData.append("lastName", lastName);
            myFormData.append("phone", phoneNumber);
            myFormData.append("address", address);
            myFormData.append("otherAddress", otherAddress);
            myFormData.append("divisionId", divisionId);
            myFormData.append("districtId", districtId);
            myFormData.append("postal_code", postalCode);
            Axios.post(ApiUrl.AddShippingAddress, myFormData, { headers: { 'Authorization': `Bearer  ${token}` } }).then((response) => {
                if (response.status === 200) {
                    addAddress()
                    handleClose()
                } else if (response.status === 200 && response.data.status === 0) {
                    for (let error of response.data.message) {
                        toast.error(error)
                    }
                }
            }).catch(function () {
                toast.error("Request failed ! try again");
            })
        }
    }
    const myDivision = division.map((division, i) => {
        return (
            <option key={i} value={division.id}>{division.name}</option>
        )
    })
    const myDistrict = district.map((district, i) => {
        return (
            <option key={i} value={district.id}>{district.name}</option>
        )
    })
    return (
        <Fragment>
            <Modal className="change-shipping-modal" show={addAddressShow} onHide={handleClose} animation={false}>
                <Modal.Header closeButton style={{ border: 'none' }}>
                </Modal.Header>
                <Modal.Title className="text-center" style={{ fontSize: '22px', fontWeight: 700, color: '#5C5C5C' }}>ADD A
                    NEW SHIPPING ADDRESS</Modal.Title>
                <Modal.Body>
                    <p className="text-center" style={{ fontSize: '15px', color: '#5C5C5C' }}>Please fill in the
                        information below:</p>
                    <div className={classes.root} noValidate autoComplete="off">
                        <div className="address-edit-box">
                            <TextField style={{margin: "0px"}} onChange={getFirstName} id="firstName" label="First name" type="text"
                                variant="outlined" placeholder="First name" />
                            <TextField style={{margin: "0px"}} onChange={getLastName} id="lastName" label="Last name" type="search"
                                variant="outlined" placeholder="Last name" />
                            <TextField style={{margin: "0px"}} onChange={getPhoneNumber} id="phoneNumber" label="Mobile Number" type="search"
                                variant="outlined" placeholder="Mobile Number" />
                            <TextField style={{margin: "0px"}} onChange={getAddress} id="address" label="Address 1" type="search"
                                variant="outlined" placeholder="Address 1" />
                            <TextField style={{margin: "0px"}} onChange={getOtherAddress} id="otherAddress" label="Address 2" type="search"
                                variant="outlined" placeholder="Address 2" />
                            <div className="row">
                                <div className="col-12">
                                    <div className="" style={{marginRight: "15px", padding: "15px", paddingRight: "0px"}}>
                                        <select onChange={getDivision} className="form-select select-division" id="division">
                                            <option value="" selected disabled hidden>Select division</option>
                                            {myDivision}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="" style={{marginRight: "15px", padding: "15px", paddingRight: "0px"}}>
                                        <select onChange={getDistrict} className="form-select select-distict" id="district">
                                            <option value="" selected disabled hidden >Select district</option>
                                            {myDistrict}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <TextField style={{margin: "0px"}} onChange={getPostalCode} id="postalCode" label="Postal  code"
                                        type="search" variant="outlined" placeholder="Postal Code" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className={`justify-content-center`}>
                    <button onClick={addShippingAddress} className="btn btn-dark mt-4 ms-4">ADD ADDRESS</button>
                </Modal.Footer>
            </Modal>
            <Toaster position="top-right" reverseOrder={false} />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    userInfo: state.builder.userInfo,
    accessToken: state.builder.accessToken,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(AddNewAddress);

