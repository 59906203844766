import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import MobileHeader from '../Header/MobileHeader'
import toast from "react-hot-toast";
import Axios from "axios";
import ApiUrl from "../../../api/ApiUrl";
import ReactHtmlParser from 'react-html-parser';
import Accordion from "react-bootstrap/Accordion";

const Faqs = () => {
    const [myData, setMyData] = useState([])
    useEffect(() => {
        Axios.get(ApiUrl.Faqs).then(response => {
            if (response.status === 200) {
                setMyData(response.data.data)
            }
        }).catch(error => {
            toast.error("Request failed ! try again");
        })
    }, [])
    return (
        <div>
            <Header />
            <MobileHeader />
            <div className="container">
                <div className="row">
                    <div className="footer-menu-title text-center">Faq</div>
                </div>
                <div className="police-page-wrap">
                <div className="row">
                {
                    myData.map((value, i)=>{
                        return (
                            <div className="col-md-9 mx-auto" key={i}>
                            <div className="footer-menu-content">
                                <Accordion>
                                    <Accordion.Item className="policy-item" eventKey="0">
                                        <Accordion.Header>{ReactHtmlParser(value.title)}</Accordion.Header>
                                        <Accordion.Body>
                                            <p>{ReactHtmlParser(value.body)}</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        )
                    })
                }
            </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Faqs
