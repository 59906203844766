import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import MobileHeader from '../Header/MobileHeader';
import toast from "react-hot-toast";
import Validation from "../../../validation/Validation";
import LocalStorageHelper from "../../../LocalStorageHelper/LocalStorageHelper";
import Axios from "axios";
import ApiUrl from "../../../api/ApiUrl";

class RecoverPassword extends Component {
    constructor() {
        super();
        this.state = {
            btn: "Create my account",
            mobile: "",
            UserRedirect: false,
            errorMsg: ""
        }
        this.mobileOnChange = this.mobileOnChange.bind(this);
        this.onNextClick = this.onNextClick.bind(this);
        this.onUserRedirect = this.onUserRedirect.bind(this);
    }

    mobileOnChange = (even) => {
        let mobile = even.target.value
        this.setState({mobile: mobile})
    }
    onUserRedirect = () => {
        if (this.state.UserRedirect === true) {
            return <Redirect to={"/otp-reset-password"}/>
        }
    }
    onNextClick = () => {
        let mobile = this.state.mobile
        if (mobile.length === 0) {
            toast.error("Mobile Number Required")
        } else if (!(Validation.MobileRegx).test(mobile)) {
            toast.error("Invalid Mobile Number")
        } else {
            this.setState({btn: LocalStorageHelper.loadingBtn()})
            LocalStorageHelper.setUserMobile(this.state.mobile)
            let myFormData = new FormData();
            myFormData.append("phone", mobile);
            myFormData.append("type", 1);
            myFormData.append("ip", "554544");
            Axios.post(ApiUrl.ForgotPassword, myFormData).then((response) => {
                if (response.status === 200 && response.data.status === 1) {
                    toast.success("Verification code has been sent")
                    this.setState({UserRedirect: true});
                } else if (response.status === 200 && response.data.status === 2) {
                    for (let error of response.data.message) {
                        this.setState({errorMsg: error})
                    }
                    this.setState({btn: "Create my account"})
                } else {
                    for (let error of response.data.message) {
                        toast.error(error)
                    }
                    this.setState({btn: "Create my account"})
                }
            }).catch(function () {
                toast.error("Request failed ! try again");
            })
        }
    }

    render() {
        return (
            <div>
                <Header/>
                <MobileHeader/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-7 col-sm-10 col-12 mx-auto">
                            <div className="d-block d-sm-none py-5 my-4 text-center" style={{lineHeight: '18px'}}>
                                <div className="d-inline-block text-end">
                                    <p className="m-0"><strong>oh</strong><span
                                        style={{fontSize: '20px', fontWeight: 300}}>Dhaka</span></p>
                                    <span>.com</span>
                                </div>
                            </div>
                            <div className="login-body pb-5 pt-sm-5 my-sm-5 signup-body-mobile-view">
                                <h1 className="title-h3 text-center text-uppercase">Recover password</h1>
                                <p className="text-center">Please enter your mobile number:</p>
                                <form className="form">
                                    <div className="form-group mb-3">
                                        <input onChange={this.mobileOnChange} type="number" className="form-control"
                                               id="mobile" placeholder="Mobile Number"/>
                                    </div>
                                    <div className="form-group mb-3">
                                        <span onClick={this.onNextClick}
                                              className="btn base-button text-light d-block text-uppercase">Recover</span>
                                    </div>
                                    <div className="form-group text-center">
                                        <span> Remember your password?</span>
                                        <a href="/login" className="create-link-txt"> Back to login</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {this.onUserRedirect()}
                <Footer/>
            </div>
        );
    }
}

export default RecoverPassword;