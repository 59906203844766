import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import MobileHeader from '../../Header/MobileHeader';
import AddNewAddress from './AddNewAddress';
import Axios from "axios";
import ApiUrl from "../../../../api/ApiUrl";
import toast from "react-hot-toast";
import { Redirect } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {eshop} from "../../../../redux/reducers";
import {connect} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
            padding: '15px',
        },
    },
}));

const MyAddress = (props) => {
    const [token,] = useState(props.accessToken);
    const [UserRedirect, setUserRedirect] = useState(false);
    const [myAddressData, setMyAddressData] = useState([]);
    const [show, setShow] = useState(false);
    const [addAddressShow, setAddAddressShow] = useState(false);
    const handleClose = () => setShow(false);
    const classes = useStyles();
    const [editAddressData, setEditAddressData] = useState({});
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [address, setAddress] = useState("");
    const [otherAddress, setOtherAddress] = useState("");
    const [division, setDivision] = useState([]);
    const [divisionId, setDivisionId] = useState("");
    const [district, setDistrict] = useState([]);
    const [districtId, setDistrictId] = useState("");
    const [postalCode, setPostalCode] = useState("");

    useEffect(() => {
        // Division
        Axios.get(ApiUrl.Division).then(response => {
            if (response.status === 200) {
                setDivision(response.data.data)
            }
        }).catch(error => {
            toast.error("Request Failde ! Try Again");
        })
    }, [])

    const getFirstName = (event) => {
        setFirstName(event.target.value)
        if (firstName.length === 0) {
            document.getElementById("firstName").style.border = "";
        }
    }
    const getLastName = (event) => {
        setLastName(event.target.value)
        if (lastName.length === 0) {
            document.getElementById("lastName").style.border = "";
        }
    }
    const getPhoneNumber = (event) => {
        setPhoneNumber(event.target.value)
        if (phoneNumber.length === 0) {
            document.getElementById("phoneNumber").style.border = "";
        }
    }
    const getAddress = (event) => {
        setAddress(event.target.value)
        if (address.length === 0) {
            document.getElementById("address").style.border = "";
        }
    }
    const getOtherAddress = (event) => {
        setOtherAddress(event.target.value)
        if (otherAddress.length === 0) {
            document.getElementById("otherAddress").style.border = "";
        }
    }
    const getDivision = (event) => {
        setDivisionId(event.target.value)
        divisions(event.target.value)
        if (event.target.value) {
            document.getElementById("division").style.border = ""
        }
    }
    const divisions = (divisionId) => {
        Axios.get(ApiUrl.District(divisionId)).then(response => {
            if (response.status === 200) {
                setDistrict(response.data.data)
            }
        }).catch(error => {
            toast.error("Request Failde ! Try Again");
        })
    }
    const getDistrict = (event) => {
        setDistrictId(event.target.value)
        Axios.get(ApiUrl.ShippingCost(event.target.value)).then(response => {
            if (response.status === 200) {
            }
        }).catch(error => {
            toast.error("Request Failde ! Try Again");
        })
        if (event.target.value) {
            document.getElementById("district").style.border = ""
        }
    }
    const getPostalCode = (event) => {
        setPostalCode(event.target.value)
        if (postalCode.length === 0) {
            document.getElementById("postalCode").style.border = "";
        }
    }
    useEffect(() => {
        if (props.accessToken) {
            myAddressList()
        }
    }, [])
    const myAddressList = () => {
        Axios.get(ApiUrl.MyAddress(props.userInfo.id), { headers: { 'Authorization': `Bearer  ${token}` } }).then(response => {
            if (response.status === 200) {
                setMyAddressData(response.data.data)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                props.setAccessToken()
                window.open("/login", "_self");
            }
        })
    }
    const EditAddressValue = (id) => {
        Axios.get(ApiUrl.EditAddress(id), { headers: { 'Authorization': `Bearer  ${token}` } }).then(response => {
            if (response.status === 200) {
                setEditAddressData(response.data.data)
                setFirstName(response.data.data.firstName)
                setLastName(response.data.data.lastName)
                setPhoneNumber(response.data.data.phone)
                setAddress(response.data.data.address)
                setOtherAddress(response.data.data.otherAddress)
                setDivisionId(response.data.data.divisionId)
                setDistrictId(response.data.data.districtId)
                setPostalCode(response.data.data.postal_code)
                setShow(true)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                props.setAccessToken()
                setUserRedirect(true)
            } else {
                toast.error("Request failed ! try again");
            }
        })
    }
    const removeMyAddress = (id) => {
        Axios.get(ApiUrl.RemoveMyAddress(id), { headers: { 'Authorization': `Bearer  ${token}` } }).then(response => {
            if (response.status === 200) {
                for (let success of response.data.message) {
                    toast.success(success)
                }
                myAddressList()
            }
        }).catch(error => {
            if (error.response.status === 401) {
                props.setAccessToken()
                setUserRedirect(true)
            } else {
                toast.error("Request failed ! try again");
            }
        })
    };
    const addressUpdate = (id) => {
        if (firstName.length === 0 || lastName.length === 0 || phoneNumber.length === 0 || address.length === 0 || otherAddress.length === 0 || divisionId == "" || districtId == "" || postalCode.length === 0) {
            if (firstName === "") {
                document.getElementById("firstName").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (lastName === "") {
                document.getElementById("lastName").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (phoneNumber === "") {
                document.getElementById("phoneNumber").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (address === "") {
                document.getElementById("address").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (otherAddress === "") {
                document.getElementById("otherAddress").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (divisionId == "") {
                document.getElementById("division").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (districtId == "") {
                document.getElementById("district").style = `border: 2px solid #f00; margin-top: -6px`;
            }
            if (postalCode === "") {
                document.getElementById("postalCode").style = `border: 2px solid #f00; margin-top: -6px`;
            }
        } else {
            let myFormData = new FormData();
            myFormData.append("id", id);
            myFormData.append("firstName", firstName);
            myFormData.append("lastName", lastName);
            myFormData.append("phone", phoneNumber);
            myFormData.append("address", address);
            myFormData.append("otherAddress", otherAddress);
            myFormData.append("divisionId", divisionId);
            myFormData.append("districtId", districtId);
            myFormData.append("postal_code", postalCode);
            Axios.post(ApiUrl.UpdateShippingAddress, myFormData, { headers: { 'Authorization': `Bearer  ${token}` } }).then((response) => {
                if (response.status === 200) {
                    for (let success of response.data.message) {
                        toast.success(success)
                    }
                    handleClose()
                    myAddressList()
                } else if (response.status === 200 && response.data.status === 0) {
                    for (let error of response.data.message) {
                        toast.error(error)
                    }
                }
            }).catch(function () {
                toast.error("Request failed ! try again");
            })
        }
    }
    const addShippingAddress = () => {
        myAddressList()
    }
    const setDefault = (id) => {
        Axios.get(ApiUrl.SetdefaultShippingAddress(props.userInfo.id, id), { headers: { 'Authorization': `Bearer  ${token}` } }).then(response => {
            if (response.status === 200) {
                for (let success of response.data.message) {
                    toast.success(success)
                }
                myAddressList()
            }
        }).catch(error => {
            if (error.response.status === 401) {
                props.setAccessToken()
                window.open("/login", "_self");
            }
        })
    }
    const onUserRedirect = () => {
        if (UserRedirect === true) {
            return <Redirect to={"/login"} />
        }
    }
    const handleAddAddressClose = () => {
        setAddAddressShow(false);
    };

    const myDivision = division.map((division, i) => {
        return (
            <option selected={divisionId == division.id ? "selected" : ""} key={i} value={division.id}>{division.name}</option>
        )
    })
    const myDistrict = district.map((district, i) => {
        return (
            <option selected={districtId == district.id ? "selected" : ""} key={i} value={district.id}>{district.name}</option>
        )
    })
    return (
        <Fragment>
            <AddNewAddress addAddress={addShippingAddress} addAddressShow={addAddressShow}
                handleClose={handleAddAddressClose} />
            <Header />
            <MobileHeader />
            <div className="container">
                <div className="buyer-profile-wrap">
                    <div className="row">
                        <div className="logout-btn">
                            <Link to="/buyer-profile" className=""><span>&#5176;</span> BACK TO ACCOUNT </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="address-name">
                            <h5>MY ADDRESSES</h5>
                        </div>
                        <div className="add-address-btn">
                            <button className="btn btn-dark" onClick={() => {
                                setAddAddressShow(true);
                            }}>ADD A NEW ADDRESS
                            </button>
                        </div>
                    </div>
                    <div className="buyer-profile-main">
                        <div className="row">
                            <div className="other-info-title">
                                <h5>DEFAULT ADDRESS</h5>
                            </div>
                            {
                                myAddressData.map((value, i) => {
                                    return (
                                        <div key={i} className="col-md-4">
                                            <div className="buyer-other-info ml0">
                                                <input onClick={() => {
                                                    setDefault(value.id)
                                                }} className="" id={value.lastName + value.id} type="radio"
                                                    name="address" checked={value.set_default != 1 ? 0 : 1} />
                                                <label for={value.lastName + value.id} className="add-other-info">
                                                    <p className="other-info-name"
                                                        style={{ margin: "0px" }}>{value.firstName} {value.lastName}</p>
                                                    <p style={{
                                                        margin: "0px",
                                                        marginBottom: "5px"
                                                    }}>{value.address},{value.otherAddress},{value.divisionId},{value.districtId} -{value.postal_code}</p>
                                                    <h6 style={{ margin: "0px" }}>{value.phone}</h6>
                                                    <div className="edit-delete-address-btn d-flex ">
                                                        <Button onClick={() => EditAddressValue(value.id)}
                                                            className="delete-dflt-address"> Edit </Button>
                                                        {value.set_default != 1 ?
                                                            <Button onClick={() => removeMyAddress(value.id)}
                                                                className="delete-dflt-address"> DELETE </Button> : ""}
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {/*model */}
                            <Modal show={show} onHide={handleClose} animation={false}>
                                <Modal.Header closeButton style={{ border: 'none' }}> </Modal.Header>
                                <Modal.Title className="text-center"
                                    style={{ fontSize: '22px', fontWeight: 700, color: '#5C5C5C' }}>EDIT AN
                                    ADDRESS</Modal.Title>
                                <Modal.Body>
                                    <p className="text-center" style={{ fontSize: '15px', color: '#5C5C5C' }}>Please fill
                                        in the information below:</p>
                                    <div className={classes.root} noValidate autoComplete="off">
                                        <div className="address-edit-box">
                                            <TextField onChange={getFirstName} id="firstName" label="First name"
                                                type="search" variant="outlined"
                                                defaultValue={editAddressData.firstName} />
                                            <TextField onChange={getLastName} id="lastName" label="Last name"
                                                type="search" variant="outlined"
                                                defaultValue={editAddressData.lastName} />
                                            <TextField onChange={getPhoneNumber} id="phoneNumber" label="Mobile Number"
                                                type="search" variant="outlined"
                                                defaultValue={editAddressData.phone} />
                                            <TextField onChange={getAddress} id="address" label="Address 1"
                                                type="search" variant="outlined"
                                                defaultValue={editAddressData.address} />
                                            <TextField onChange={getOtherAddress} id="otherAddress" label="Address 2"
                                                type="search" variant="outlined"
                                                defaultValue={editAddressData.otherAddress} />
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="" style={{ margin: "0px 8px", padding: "15px", paddingRight: "0px" }}>
                                                        <select onChange={getDivision} className="form-select select-division" id="division">
                                                            <option value="" selected disabled hidden>Select division</option>
                                                            {myDivision}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="" style={{ margin: "0px 8px", padding: "15px", paddingRight: "0px" }}>
                                                        <select onChange={getDistrict} className="form-select select-distict" id="district">
                                                            <option value="" selected disabled hidden >Select district</option>
                                                            {myDistrict}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12">
                                                    <TextField onChange={getPostalCode} id="postalCode"
                                                        label="Postal  code" type="search" variant="outlined"
                                                        defaultValue={editAddressData.postal_code} />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <button onClick={() => {
                                                        addressUpdate(editAddressData.id)
                                                    }} className="edit-an-address-btn mt-4 ms-4">EDIT AN ADDRESS
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer> </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {onUserRedirect()}
        </Fragment>
    )
};

const mapStateToProps = state => ({
    userInfo: state.builder.userInfo,
    accessToken: state.builder.accessToken,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(MyAddress);