import React, { useEffect, useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import Axios from "axios";
import ApiUrl from "../../../../api/ApiUrl";
import toast from "react-hot-toast";
import ReactHtmlParser from "react-html-parser";

const PrivacyPolicy = () => {
    const [show, setShow] = useState(false);
    const [myData, setMyData] = useState({})

    const cartModalClose = () => setShow(false);
    const cartModalShow = () => setShow(true);

    useEffect(() => {
        Axios.get(ApiUrl.PrivacyPolicy).then(response => {
            if (response.status === 200) {
                setMyData(response.data.data)
            }
        }).catch(error => {
            toast.error("Request failed ! try again");
        })
    }, [])

    return (
        <div>
            <div className="" onClick={cartModalShow}>
                Privacy policy.
            </div>

            <Modal className="condition-modal"  show={show} onHide={cartModalClose} animation={false}>
                <Modal.Header closeButton style={{ border: 'none' }}>
                </Modal.Header>
                <Modal.Title className="text-center" style={{ fontSize: '25px', fontWeight: '600' }}><span>{myData.title}</span></Modal.Title>
                <Modal.Body>
                    <div className="trams-condition-wrap">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="footer-menu-content">
                                    <p>{ReactHtmlParser(myData.content)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ border: 'none' }}>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default PrivacyPolicy
