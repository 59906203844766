import React from 'react';
import { Link } from 'react-router-dom';
import cartProductImg from '../../../../dist/images/cartproductimg.png';
import CartQuantity from './CartQuantity';


const CartProductList  = () => {

        return (
            <div>
                <div className="cartlist-header mt-5">
                    <div className="row">
                        <div className="col-lg-5 col-md-5">
                            <h6 className="">PRODUCT</h6>
                        </div>
                        <div className="col-lg-3 col-md-3 text-center">
                            <h6 className="">QUANTITY</h6>
                        </div>
                        <div className="col-lg-4 col-md-4 text-end">
                            <h6 className="">TOTAL</h6>
                        </div>
                    </div>
                </div>
                <div className="cartlist-body">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-12">
                            <div className="d-flex">
                                <div className="product-img">
                                    <img className="img-fluid img-bg" src={cartProductImg} alt="Cart Product Image" title="Cart Product Image" />
                                </div>
                                <div className="product-details p-3">
                                    <h6 className="item-title">MAN’S SHIRT TITLE HERE NAME</h6>
                                    <h6 className="item-color mb-2">BALCK / S</h6>
                                    <h6 className="item-price">৳500</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 d-sm-none d-md-none d-lg-none"></div>
                        <div className="col-lg-3 col-md-3 col-8 ">
                            <div className="row">
                                <div className="col-md-12 col-6">
                                    <div className="d-flex justify-content-center mb-2 mt-lg-4">
                                        <CartQuantity />
                                    </div>
                                </div>
                                <div className="col-md-12 col-6 p text-center">
                                    <Link to="#" className="text-dark cart-remove-link">REMOVE</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 d-none d-lg-block">
                            <div className="text-end">
                                <h6 className="mt-5">৳500</h6>
                            </div>
                        </div>
                    </div>
                </div>
                          
            </div>
        );
}

export default CartProductList;