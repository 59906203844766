import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import EditProfile from './EditProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import ImageUploading from "react-images-uploading";
import ChangePass from '../ChangePass';
import Axios from "axios";
import ApiUrl from "../../../../api/ApiUrl";
import LocalStorageHelper from "../../../../LocalStorageHelper/LocalStorageHelper";
import toast from "react-hot-toast";
import {eshop} from "../../../../redux/reducers";
import {connect} from "react-redux";
import BuyerDfltImg from '../../../../dist/images/buyer/1.png';

const PersonalInfo = ({
    profile,
    userInfo,
    editProfile,
    changePassword,
    controlChangePassword,
    controlEditProfile,
    refreshPage,
     accessToken,
     setAccessToken,

}) => {

    const [token] = useState(accessToken);
    const [primaryAddressData, setPrimaryAddressData] = useState({});
    const [images, setImages] = React.useState();
    const maxNumber = 69;
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
        if (imageList[0].file.name) {
            CustomerImage(imageList[0].file)
        }
    };
    const CustomerImage = (avatar) => {
        let myFormData = new FormData();
        myFormData.append("id", userInfo.id);
        myFormData.append("avatar", avatar);
        Axios.post(ApiUrl.CustomerImage, myFormData, { headers: { 'Authorization': `Bearer  ${token}` } }).then((response) => {
            if (response.status === 200 && response.data.status === 1) {

            }
        }).catch(error => {
            if (error.response.status === 401) {
                setAccessToken()
                window.open("/login", "_self");
            }
        })
    }
    useEffect(() => {
        if (userInfo.id != null) {
            Axios.get(ApiUrl.addressSetDefault(userInfo.id), { headers: { 'Authorization': `Bearer  ${token}` } }).then(response => {
                if (response.status === 200) {
                    setPrimaryAddressData(response.data.data)
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    setAccessToken("")
                    window.open("/login", "_self");
                } else {
                    toast.error("Request failed ! try again");
                }
            })
        }
    }, [userInfo]);

    return (
        <div>
            {
                editProfile ? (
                    <EditProfile profile={profile} profileDataShow={userInfo} controlEditProfile={controlEditProfile}
                        primaryAddressData={primaryAddressData} />)
                    :
                    changePassword ? (
                        <ChangePass profileDataShow={userInfo} controlChangePassword={controlChangePassword}
                            refreshPage={refreshPage} primaryAddressData={primaryAddressData} />) :

                        (<div className="row">
                            <div className="col-md-9">
                                <div className="buyer-profile-details">
                                    <div className="buyer-profile-img">
                                        <div className="">
                                            <ImageUploading
                                                value={images}
                                                onChange={onChange}
                                                maxNumber={maxNumber}
                                                dataURLKey="data_url"
                                            >
                                        {({
                                            imageList,
                                            onImageUpload,
                                            isDragging,
                                            dragProps
                                        }) => (
                                            // write your building UI
                                            <div className="upload__image-wrapper">
                                            <div className="img-upld-btn"
                                            style={isDragging?{ color: "red" }: null}
                                            onClick={onImageUpload} {...dragProps}>
                                            <FontAwesomeIcon icon={faCamera} />
                                            </div>
                                        {userInfo.avatar_original?<img className="profile-dflt-img"
                                            src={ApiUrl.showImage(userInfo.avatar_original)}
                                            alt="" />:

                                            <img className="profile-dflt-img" src={BuyerDfltImg}
                                            alt="" />
                                                        }

                                            &nbsp;
                                        {imageList.map((image, index) => (
                                            <div key={index} className="image-item">
                                            <img src={image.data_url} alt="" width="100" />
                                            </div>
                                        ))}
                                            </div>
                                                )}
                                            </ImageUploading>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="personal-info-box">
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="profile-detail">
                                                            <div className="row">
                                                                <div className="col-md-6 col-6">
                                                                    <span>Name</span>
                                                                </div>
                                                                <div className="col-md-6 col-6">
                                                                    <span
                                                                        className="profile-history">{userInfo?.name} {userInfo?.last_name}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="profile-detail">
                                                            <div className="row">
                                                                <div className="col-md-6 col-6">
                                                                    <span>Mobile Number</span>
                                                                </div>
                                                                <div className="col-md-6 col-6">
                                                                    <span
                                                                        className="profile-history">{userInfo?.phone}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="profile-detail">
                                                            <div className="row">
                                                                <div className="col-md-6 col-6">
                                                                    <span>Email</span>
                                                                </div>
                                                                <div className="col-md-6 col-6">
                                                                    <span
                                                                        className="profile-history">{userInfo?.email}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="profile-detail">
                                                            <div className="row">
                                                                <div className="col-md-6 col-6">
                                                                    <span>Gender</span>
                                                                </div>
                                                                <div className="col-md-6 col-6">
                                                                    <span
                                                                        className="profile-history">{userInfo?.gender == 1 ? "Male" : userInfo?.gender == 2 ? "Female" : ""}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="profile-detail">
                                                            <div className="row">
                                                                <div className="col-md-6 col-6">
                                                                    <span>Date of Birth</span>
                                                                </div>
                                                                <div className="col-md-6 col-6">
                                                                    <span
                                                                        className="profile-history">{userInfo?.dob}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="profile-detail">
                                                            <div className="row">
                                                                <div className="col-md-6 col-6">
                                                                    <span>Division</span>
                                                                </div>
                                                                <div className="col-md-6 col-6">
                                                                    <span
                                                                        className="profile-history">{userInfo?.divisionName}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="profile-detail">
                                                            <div className="row">
                                                                <div className="col-md-6 col-6">
                                                                    <span>District</span>
                                                                </div>
                                                                <div className="col-md-6 col-6">
                                                                    <span
                                                                        className="profile-history">{userInfo?.districtName}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="profile-detail">
                                                            <div className="row">
                                                                <div className="col-md-6 col-6">
                                                                    <span>Thana</span>
                                                                </div>
                                                                <div className="col-md-6 col-6">
                                                                    <span
                                                                        className="profile-history">{userInfo?.thanaName}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <div className="profile-detail">
                                                            <div className="row">
                                                                <div className="col-md-6 col-6">
                                                                    <span>Password</span>
                                                                </div>
                                                                <div className="col-md-6 col-6">
                                                                    <span
                                                                        className="profile-history">{userInfo?.password}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="">
                                                            <div className="row">
                                                                <div className="col-md-6">

                                                                </div>
                                                                <div className="col-md-6">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link to="#" className="change-pass" onClick={() => {
                                                    controlChangePassword()
                                                }}> Change </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="edit-profile-btn">
                                        <Link to="#" className="buyer-profile-edit" onClick={() => {
                                            controlEditProfile(!editProfile)
                                        }}> EDIT PROFILE </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="buyer-other-info">
                                    <div className="other-info-title">
                                        <h5>PRIMARY ADDRESS </h5>
                                    </div>
                                    <div className="other-info-detail">
                                        {primaryAddressData.id ? (<div><p className="other-info-name">{primaryAddressData?.firstName} {primaryAddressData?.lastName}</p><p>{primaryAddressData?.address}, {primaryAddressData?.divisionId + ","} {primaryAddressData?.districtId} {"-" + primaryAddressData?.postal_code} </p></div>) : " "}
                                    </div>
                                    <div className="edit-address-btn">
                                        <Link to="/my-address" className="buyer-address-edit"> EDIT addresses </Link>
                                    </div>
                                </div>
                            </div>
                        </div>)}
        </div>
    )
}

const mapStateToProps = state => ({
    accessToken: state.builder.accessToken,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(PersonalInfo);