import React, {Component} from 'react';
import ApiUrl from "../../../../api/ApiUrl";
import Axios from "axios";
import toast, {Toaster} from "react-hot-toast";
import {connect} from "react-redux";
import {eshop} from "../../../../redux/reducers";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEraser} from '@fortawesome/free-solid-svg-icons'

class ShippingAmountTotal extends Component {
    state = {
        code: "",
        userId: "",
        type: "",
        token: this.props.accessToken,
        setValidation: "",
        coupon: {},
        percent: "",
    }

    componentDidMount() {
        if (this.props.userInfo.id != null) {
            this.setState({userId: this.props.userInfo.id, type: "user"})
        }
    }
    getCode = (event) => {
        this.setState({code: event.target.value})
        if (this.state.code.length >= 1) {
            this.setState({setValidation: ""})
        }
    }
    couponApply = () => {
        let code = this.state.code;
        let userId = this.state.userId;
        if (this.props.accessToken) {
            if (code.length === 0) {
                this.setState({setValidation: "validation-select-box"})
            } else {
                let myFormData = new FormData();
                myFormData.append("code", code);
                myFormData.append("user_id", userId);
                myFormData.append("cart", JSON.stringify(this.props.cart));
                Axios.post(ApiUrl.CouponApply, myFormData, {headers: {'Authorization': `Bearer  ${this.state.token}`}}).then((response) => {
                    if (response.data.success) {
                        this.setState({coupon: response.data})
                        this.props.setCouponDiscount(response.data)
                        if (response.data.percent > 0) {
                            this.setState({percent: "(" + response.data.percent + "%)"})
                        }
                    } else {
                        for (let error of response.data.message) {
                            toast.error(error)
                        }
                    }
                }).catch(() => {

                })
            }
        } else {
           toast.error("Please login First ");
        }
     }
    couponReset = () =>{
        this.setState({code: ""})
        this.props.setCouponDiscount({});
    }
    render() {
        let sutTotal = 0;
        let totalTax = 0;
        let ship = this.props.estimateInfo.shippingCost;

        const viewData = this.props.cart && this.props.cart.map((v, i) => {
            totalTax += (+v.tax) * +v.quantity;
            sutTotal += (+v.base_discounted_price ? +v.base_discounted_price : +v.base_price) * +v.quantity;

            return (
                <div key={i} className="row mb-4">
                    <div className="col-md-9">
                        <div className="d-flex align-items-center">
                            <div className="cart-prod-img-box text-center">
                                <img src={ApiUrl.showImage(v.thumbnail_image)} alt="Product Image" title="Product Image"/>
                                <span>{v.quantity}</span>
                            </div>
                            <div>
                                <h5 className="cart-prod-sm-title text-uppercase mb-1">{v.name}</h5>
                                <h5 className="m-0 cart-prod-color-title">{v.variant}</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 text-end d-none d-md-block">
                        <div className="price mt-4">
                            ৳{+v.quantity * (+v.base_discounted_price ? +v.base_discounted_price : +v.base_price)}
                            {v.tax && (
                                <p className="cart-shipping-product-tax m-0">{`(Tax: ৳${+v.tax * +v.quantity})`}</p>
                            )}
                        </div>
                    </div>
                </div>
            )
        });
               this.props.setSubTotal(+sutTotal)
               this.props.setGrandTotal(+sutTotal + (+totalTax + Number(ship)))

        return (
            <div className="cart-shipping-amount mt-3 mt-3">
                <div className="right-bg d-none d-md-block"></div>
                {viewData}
                <hr/>
                <div className="discount-box d-flex">
                    <input onChange={this.getCode} type="text" value={this.state.code} className="form-control coupon-inp" id={this.state.setValidation} placeholder="Gift card or discount code"/>

                    {this.props.accessToken ? (<span>{this.props.couponDiscount.discount > 0 ? (<button className="apply-coupon-btn">Applied <span className="coupon-refresh-btn" onClick={this.couponReset}><FontAwesomeIcon icon={faEraser}/></span></button>): (<button className="apply-coupon-btn" onClick={this.couponApply}>Apply</button>)}</span>) : (<div className="apply-coupon-btn">Apply</div>)}

                </div>
                <hr/>
                <table className="table cart-total-amnt-table">
                    <tbody>
                    <tr>
                        <td>Subtotal</td>
                        <td className="text-end bold-td">৳{sutTotal}</td>
                    </tr>
                    {this.props.couponDiscount.discount > 0 ? <tr>
                        <td>Discount {this.props.couponDiscount.percent > 0 ? "("+this.props.couponDiscount.percent+"%)" : ""}</td>
                        <td className="text-end bold-td"> ৳{this.props.couponDiscount.discount}</td>
                    </tr> : ""}
                    <tr>
                        <td>Shipping + Handling</td>
                            <td className="text-end bold-td">৳{this.props.estimateInfo.shippingCost}</td>
                    </tr>
                    <tr>
                        <td>Taxes</td>
                        <td className="text-end bold-td">৳{totalTax}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td>Total</td>
                      <td className="text-end bold-td">BD <span
                            style={{fontSize: '22px'}}>৳{Number(this.props.grandTotal) - (+this.props.couponDiscount.discount ? +this.props.couponDiscount.discount : 0)}</span>
                        </td>

                    </tr>
                    </tfoot>
                </table>
            </div>
           
        );
    }
}
const mapStateToProps = state => ({
    cart: state.builder.cart,
    estimateInfo: state.builder.estimateInfo,
    userInfo: state.builder.userInfo,
    couponDiscount: state.builder.couponDiscount,
    accessToken: state.builder.accessToken,
    grandTotal: state.builder.grandTotal,
    previousUrl: state.builder.previousUrl,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(ShippingAmountTotal);