import React from 'react';
import ReactDOM from 'react-dom';
import store, {persistor} from "./redux/store";
import App from './frontend/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

ReactDOM.render(
    <React.StrictMode>
        <App
            store={store}
            persistor={persistor}
            basename={PUBLIC_URL}
        />
    </React.StrictMode>,
    document.getElementById('root')
);
