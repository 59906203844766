import React, {useEffect, useState} from 'react';
import Header from '../Header/Header';
import MobileHeader from '../Header/MobileHeader';
import Footer from '../Footer/Footer';
import {HashLink as Link} from 'react-router-hash-link';
import PersonalInfo from './components/PersonalInfo';
import MyOrder from './components/MyOrder';
import ProductWishlist from './components/ProductWishlist';
import Message from './components/Message';
import Axios from "axios";
import ApiUrl from "../../../api/ApiUrl";
import LocalStorageHelper from "../../../LocalStorageHelper/LocalStorageHelper";
import toast, {Toaster} from "react-hot-toast";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {eshop} from "../../../redux/reducers";
import PromoCode from './components/PromoCode';

const BuyerProfile = (props) => {
    const [token] = useState(props.accessToken);
    const [profileData, setProfileData] = useState({});
    const [UserRedirect, setUserRedirect] = useState(false);
    const [wishListData, setWishListData] = useState([]);
    const [myOrderData, setOrderData] = useState([]);
    const [editProfile, setEditProfile] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [promoCodeData, setPromoCodeData] = useState([]);

    useEffect(() => {
        if (props.userInfo.id != null) {
            Profile()
            setTimeout(() => {
                document.getElementById("myOrderFun").click();
            }, 1000)
        }
    }, [])
    useEffect(() => {
        productOrder()
    }, [setOrderData, editProfile])
    
    const Profile = () => {
        Axios.get(ApiUrl.Profile, {headers: {'Authorization': `Bearer  ${token}`}}).then(response => {
            if (response.status === 200) {
                setProfileData(response.data)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                props.setAccessToken()
                window.open("/login", "_self");
            } else {
                toast.error("Request failed ! try again");
            }
        })
    }
    const refreshPage = () => {
        let passCheck = LocalStorageHelper.getItem("changePass");
        if (passCheck) {
            setChangePassword(false)
            localStorage.removeItem("changePass");
        }
    }
    const controlEditProfile = (status) => {
        setEditProfile(status);
    };
    const controlChangePassword = () => {
        setChangePassword(true);
        LocalStorageHelper.setItem("changePass", true)
    };
    const productWishList = () => {
        Axios.get(ApiUrl.WishList(props.userInfo.id), {headers: {'Authorization': `Bearer  ${token}`}}).then(response => {
            if (response.status === 200) {
                setWishListData(response.data.data)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                props.setAccessToken()
                window.open("/login", "_self");
            } else {
                toast.error("Request failed ! try again");
            }
        })
    }
    const productOrder = () => {
        Axios.get(ApiUrl.MyOrder(props.userInfo.id), {headers: {'Authorization': `Bearer  ${token}`}}).then(response => {
            if (response.status === 200) {
                setOrderData(response.data.data)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                props.setAccessToken()
                window.open("/login", "_self");
            } else {
                toast.error("Request failed ! try again");
            }
        })
    }
    const promoCode = () => {
        Axios.get(ApiUrl.PromoCode(props.userInfo.id), {headers: {'Authorization': `Bearer  ${token}`}}).then(response => {
            if (response.status === 200) {
                setPromoCodeData(response.data.data)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                props.setAccessToken()
                window.open("/login", "_self");
            } else {
                toast.error("Request failed ! try again");
            }
        })
    }
    const onUserRedirect = () => {
        if (UserRedirect === true) {
            return <Redirect to={"/login"}/>
        }
    }
    const logOut = () => {
        if(props.guestId){
            userTransfer()
        }
        props.setUserInfo({});
        props.setShippingAddress({});
        props.setAccessToken()
    }

    const userTransfer = () =>{
        Axios.get(ApiUrl.userTransfer(props.guestId, props.userInfo.id, "guest")).then(response=>{
            if(response.status === 200){

            }
        }).catch(error=>{
            toast.error("Request Failde ! Try Again");
        })
    }
    return (
        <div>
            <Header/>
            <MobileHeader/>
            <div className="container">
                <div className="buyer-profile-wrap">
                    {
                        LocalStorageHelper.getItem("changePass") ?
                            <div>
                                <div className="row">
                                    <div className="logout-btn">
                                        <Link to="/buyer-profile" className=""><span>&#5176;</span> BACK TO ACCOUNT
                                        </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="account-name">
                                        <h5>Change password</h5>
                                        <p>Update your password</p>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="row">
                                    <div className="logout-btn">
                                        <Link onClick={logOut} to="/" className=""> Logout </Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="account-name">
                                        <h1>My Account</h1>
                                        <p>Welcome back, {profileData?.name} {profileData?.last_name} !</p>
                                    </div>
                                </div>
                            </div>
                    }

                    <div className="buyer-profile-main">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="buyer-profile-category d-none d-lg-block">
                                    <ul className="nav nav-pills buyer-category-list" id="pills-tab" role="tablist">
                                        {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link category-item" id="pills-wallet-tab" data-bs-toggle="pill" data-bs-target="#pills-wallet" type="button" role="tab" aria-controls="pills-wallet" aria-selected="false">WALLET</button>
                                    </li> */}
                                        <li id="myOrderFun" onClick={() => {
                                            productOrder()
                                        }} className="nav-item" role="presentation">
                                            <button className="nav-link category-item active" id="pills-my-order-tab"
                                                    data-bs-toggle="pill" data-bs-target="#pills-my-order" type="button"
                                                    role="tab" aria-controls="pills-my-order" aria-selected="false">MY
                                                ORDERS
                                            </button>
                                        </li>
                                        <li onClick={() => {
                                            productWishList()
                                        }} className="nav-item" role="presentation">
                                            <button className="nav-link category-item" id="pills-wishlist-tab"
                                                    data-bs-toggle="pill" data-bs-target="#pills-wishlist" type="button"
                                                    role="tab" aria-controls="pills-wishlist"
                                                    aria-selected="false">WISHLIST
                                            </button>
                                        </li>
                                        {/*  <li className="nav-item" role="presentation">
                                        <button className="nav-link category-item" id="pills-message-tab" data-bs-toggle="pill" data-bs-target="#pills-message" type="button" role="tab" aria-controls="pills-message" aria-selected="false">MESSAGE</button>
                                    </li> */}
                                        <li className="nav-item" role="presentation" onClick={() => {
                                            refreshPage()
                                        }}>
                                            <button className="nav-link category-item" id="pills-personal-info-tab"
                                                    data-bs-toggle="pill" data-bs-target="#pills-personal-info"
                                                    type="button" role="tab" aria-controls="pills-personal-info"
                                                    aria-selected="true">PERSONAL INFORMATION
                                            </button>
                                        </li>
                                        { /*  <li className="nav-item" role="presentation">
                                        <button className="nav-link category-item" id="pills-affiliate-tab" data-bs-toggle="pill" data-bs-target="#pills-affiliate" type="button" role="tab" aria-controls="pills-affiliate" aria-selected="false">AFFILIATE</button>
                                    </li> */}
                                        <li onClick={() => {
                                            promoCode()
                                        }}  className="nav-item" role="presentation">
                                            <button className="nav-link category-item" id="pills-promo-code-tab"
                                                    data-bs-toggle="pill" data-bs-target="#pills-promo-code"
                                                    type="button" role="tab" aria-controls="pills-promo-code"
                                                    aria-selected="false">PROMO CODE
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade" id="pills-wallet" role="tabpanel"
                                         aria-labelledby="pills-wallet-tab">
                                        <div className="personal-info-title d-lg-none">
                                            <p> Wallet</p>
                                        </div>
                                    </div>
                                    <div className="tab-pane show active" id="pills-my-order" role="tabpanel"
                                         aria-labelledby="pills-my-order-tab">
                                        <div className="personal-info-title d-lg-none">
                                            <p> My Order</p>
                                        </div>
                                        <MyOrder myOrderInfo={myOrderData}/>
                                    </div>
                                    <div className="tab-pane fade" id="pills-wishlist" role="tabpanel"
                                         aria-labelledby="pills-wishlist-tab">
                                        <div className="personal-info-title d-lg-none">
                                            <p> Wish List</p>
                                        </div>
                                        <ProductWishlist wishListInfo={wishListData} productWishList={productWishList}/>
                                    </div>
                                    <div className="tab-pane fade" id="pills-message" role="tabpanel"
                                         aria-labelledby="pills-message-tab">
                                        <div className="personal-info-title d-lg-none">
                                            <p> Message</p>
                                        </div>
                                        <Message/>
                                    </div>
                                    <div className="tab-pane fade" id="pills-personal-info" role="tabpanel"
                                         aria-labelledby="pills-personal-info-tab">
                                        <div className="personal-info-title d-lg-none">
                                            <p> PERSONAL INFORMATION</p>
                                        </div>
                                        <PersonalInfo profile={Profile} userInfo={profileData} editProfile={editProfile}
                                                      changePassword={changePassword}
                                                      controlEditProfile={controlEditProfile}
                                                      controlChangePassword={controlChangePassword} refreshPage={refreshPage}/>
                                    </div>
                                    <div className="tab-pane fade" id="pills-affiliate" role="tabpanel"
                                         aria-labelledby="pills-affiliate-tab">
                                        <div className="personal-info-title d-lg-none">
                                            <p> Affiliate</p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-promo-code" role="tabpanel"
                                         aria-labelledby="pills-promo-code-tab">
                                        <div className="personal-info-title d-lg-none">
                                            <p> Promo Code</p>
                                        </div>
                                        <PromoCode promoCodeInfo={promoCodeData} promoCode={promoCode}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster position="top-right" reverseOrder={false}/>
            {onUserRedirect()}
            <Footer/>
        </div>
    )
}

const mapStateToProps = state => ({
    userInfo: state.builder.userInfo,
    accessToken: state.builder.accessToken,
});
export default connect(
    mapStateToProps,
    eshop.builder.actions
)(BuyerProfile);