import React from 'react';
import './../dist/sass/main.scss';
import Routes from './Routes';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";

export default function App({store, persistor, basename}) {
  return (
      <Provider store={store}>
        {/*<PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>*/}
        <PersistGate persistor={persistor} >
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <BrowserRouter basename={basename}>
                <LastLocationProvider>
                    <Routes />
                </LastLocationProvider>
            </BrowserRouter>
        </PersistGate>
      </Provider>
  );
}