import React, {Fragment } from "react";
import {Route} from "react-router-dom";
import UpdatePassword from "./modules/Signup/UpdatePassword";
import BuyerProfile from "./modules/BuyerProfile/BuyerProfile";
import OrderDetails from "./modules/BuyerProfile/components/OrderDetails";
import ChangePass from "./modules/BuyerProfile/ChangePass";
import MyAddress from "./modules/BuyerProfile/components/MyAddress";
import Home from "./modules/Home/Home";
import RegSuccess from "./modules/Signup/RegSuccess";
import ProductCart from "./modules/Cart/ProductCart";
import ProductDetails from "./modules/Products/ProductDetails";
import ProductCategories from "./modules/Products/ProductCategories";
import CartShipping from "./modules/Cart/CartShipping";
import CartInfo from "./modules/Cart/CartInfo";
import CartPayment from "./modules/Cart/CartPayment";
import Product from "./modules/Home/product";
import PaymentSuccess from "./modules/Cart/components/PaymentSuccess";
import PrivacyPolicy from "./modules/Pages/PrivacyPolicy";
import TermsAndConditions from "./modules/Pages/TermsAndConditions";
import Faqs from "./modules/Pages/Faqs";
import OtpResetPassword from "./modules/Signup/OtpResetPassword";

const PrivateRoutes = () => {
    return (
        <Fragment>
            <Route path="/buyer-profile" component={BuyerProfile}/>
            <Route path="/order-details" component={OrderDetails}/>
            <Route path="/buyer-profile-change-pass" exact component={ChangePass}/>
            <Route path="/my-address" component={MyAddress}/>
            <Route path="/update-password" component={UpdatePassword}/>
            {/* public */}
            {/* <Route path="/products" component={Product}/> */}
            <Route path="/otp-reset-password" component={OtpResetPassword}/>
            {/* Products Page */}
            <Route path="/reg-success" component={RegSuccess}/>
            <Route path="/category/:cat/:subCat?" component={ProductCategories}/>
            <Route path="/product-cart" component={ProductCart}/>
            <Route path="/product-details/:slug" component={ProductDetails}/>
            <Route path="/cart-shipping" component={CartShipping}/>
            <Route path="/cart-info" component={CartInfo}/>
            <Route path="/cart-payment" component={CartPayment}/>
            <Route path="/payment-success" component={PaymentSuccess}/>
            {/* Pages */}
            <Route path="/faqs" component={Faqs}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/terms-condition" component={TermsAndConditions}/>
            <Route exact path="/" component={Product}/>
        </Fragment>
    );
}
export default PrivateRoutes;